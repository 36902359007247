import { useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Skeleton } from '@material-ui/lab';
import { isXTMAffiliate, useGetAffiliatesQuery } from 'apis/affiliates';
import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { SecurityAssurance } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import {
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  TitleLarge,
} from 'components/TypographyTw';
import { TextMaskDate } from 'components/forms/TextMaskDate';
import { useValidationMsg } from 'components/forms/useValidationMsg';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';
import { registrationValidators } from 'registration/utils/Validators';
import { UserInfoSchema } from 'registration/zodForms/zodFormRegistrationUserInfo';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';

export const RegistrationUserInfoPage = ({
  userInfoForm,
  submitForm,
}: {
  userInfoForm: UseFormReturn<UserInfoSchema, any>;
  submitForm: (data: UserInfoSchema) => void;
}) => {
  const intl = useIntl();

  const [dateString, setDateString] = useState('');

  const { data: affiliateData, isLoading: isAffiliateDataLoading } =
    useGetAffiliatesQuery();
  const hasXTMAffiliation = isXTMAffiliate(affiliateData?.affiliations);

  useTrackPageViewedOnceDeprecated({
    name: 'registration-user-info',
    overrideUrl: `registration/user-info`,
  });

  useTrackElementViewedOnce({
    element: userInfoForm.formState.errors.givenName,
    name: 'given-name-input-error',
  });
  useTrackElementViewedOnce({
    element: userInfoForm.formState.errors.familyName,
    name: 'family-name-input-error',
  });
  useTrackElementViewedOnce({
    element: userInfoForm.formState.errors.dateOfBirth,
    name: 'date-of-birth-input-error',
  });

  const submit: SubmitHandler<UserInfoSchema> = (data) => {
    submitForm(data);
  };

  return (
    <form onSubmit={userInfoForm.handleSubmit(submit)}>
      <TitleLarge data-cy="user-info-title">
        {intl.formatMessage({
          id: 'Registration.UserInfoPage.Title',
          defaultMessage: 'Tell us about yourself',
        })}
      </TitleLarge>
      {isAffiliateDataLoading ? (
        <Skeleton height={80} className="w-full mt-4 mb-9 rounded-xl" />
      ) : hasXTMAffiliation ? (
        <ParagraphBold className="mt-4 mb-9 border rounded-xl p-4 border-opacity-20 border-black flex gap-6">
          <KDSIcons.Icons.Info className="text-primary-200" />
          {intl.formatMessage({
            id: 'Registration.UserInfoPage.XTMSubtitle',
            defaultMessage: 'Make sure your details match your AnyDay account',
          })}
        </ParagraphBold>
      ) : (
        <Paragraph className="mb-9">
          {intl.formatMessage({
            id: 'Registration.UserInfoPage.Subtitle',
            defaultMessage:
              'Please enter your legal first and last name exactly as they appear on your id.',
          })}
        </Paragraph>
      )}
      <KhInputTextTw
        {...userInfoForm.register('givenName')}
        className="!mb-6"
        label={intl.formatMessage({
          id: 'Registration.UserInfoPage.FirstName',
          defaultMessage: 'First name',
        })}
        trackName="given-name"
        error={!!userInfoForm.formState.errors.givenName}
        helperText={useValidationMsg(
          userInfoForm.formState.errors.givenName?.message,
        )}
        onEnterPressed={() => userInfoForm.setFocus('familyName')}
        data-cy="user-info-given-name-input"
      />
      <KhInputTextTw
        {...userInfoForm.register('familyName')}
        className="!mb-6"
        label={intl.formatMessage({
          id: 'Registration.UserInfoPage.LastName',
          defaultMessage: 'Last name',
        })}
        trackName="family-name"
        error={!!userInfoForm.formState.errors.familyName}
        helperText={useValidationMsg(
          userInfoForm.formState.errors.familyName?.message,
        )}
        onEnterPressed={() => userInfoForm.setFocus('dateOfBirth')}
        data-cy="user-info-family-name-input"
      />
      <KhInputTextTw
        {...userInfoForm.register('dateOfBirth', {
          onChange: (event) => {
            const value = event.target.value;
            if (registrationValidators.dateOfBirthDateValidator(value)) {
              setDateString(
                intl.formatDate(value, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'utc',
                }),
              );
            } else {
              setDateString('');
            }
          },
        })}
        className={clsx(dateString ? '!mb-1' : '!mb-6')}
        label={intl.formatMessage({
          id: 'Registration.UserInfoPage.DateOfBirth',
          defaultMessage: 'Date of birth (YYYY-MM-DD)',
        })}
        trackName="date-of-birth"
        error={!!userInfoForm.formState.errors.dateOfBirth}
        helperText={useValidationMsg(
          userInfoForm.formState.errors.dateOfBirth?.message,
        )}
        onEnterPressed={() => userInfoForm.handleSubmit(submit)()}
        InputProps={{ inputComponent: TextMaskDate }}
        data-cy="user-info-dob-input"
      />
      {dateString && (
        <ParagraphSmall className="mb-4">{dateString}</ParagraphSmall>
      )}

      <ButtonTw
        className="mb-6"
        data-cy="user-info-cta-button"
        disabled={!userInfoForm.formState.isValid}
        onClick={() => userInfoForm.handleSubmit(submit)()}
        trackName="move-user-info"
        type="button"
      >
        {intl.formatMessage({
          id: 'Registration.UserInfoPage.CTA',
          defaultMessage: 'Next',
        })}
      </ButtonTw>
      <SecurityAssurance />
    </form>
  );
};
