import VerificationInput from 'react-verification-input';

import { trackInput } from 'analytics/analytics';
import { ParagraphSmall } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';

export const KhInputVerificationCode = ({
  codeLength,
  errorState,
  onCodeChanged,
  onCodeCompleted,
  className,
  errorMessage,
  trackName,
  autoFocus,
}: {
  codeLength: number;
  errorState?: boolean;
  onCodeChanged: (code: string) => void;
  onCodeCompleted: (code: string) => void;
  className?: string;
  errorMessage?: string;
  trackName?: string;
  autoFocus?: boolean;
}) => {
  return (
    <div className={className}>
      <div className="flex justify-center">
        <VerificationInput
          validChars="0-9"
          inputProps={{ inputMode: 'numeric', autoComplete: 'one-time-code' }}
          placeholder=""
          length={codeLength}
          autoFocus={autoFocus}
          onChange={onCodeChanged}
          onComplete={onCodeCompleted}
          onFocus={() => {
            if (trackName) {
              trackInput({ name: trackName, type: 'Focused' });
            }
          }}
          classNames={{
            character: twMerge(
              'bg-gray-50 outline-none w-11 h-12 pt-2 pb-1 text-center font-semibold font-number text-xl rounded-[4px] caret-transparent',
              errorState ? 'border-danger-300' : '',
            ),
            characterSelected: 'border-2 !border-primary-300',
            characterInactive: 'border-2 border-gray-100',
            characterFilled: 'border-2 border-gray-100',
            container: 'verification-container',
          }}
        />
      </div>
      {errorMessage && (
        <ParagraphSmall className="text-danger-300 text-center mt-3 verification-code-error">
          {errorMessage}
        </ParagraphSmall>
      )}
    </div>
  );
};
