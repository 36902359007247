import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: LoginState = {
  destination: '/transactions',
  destinationState: null,
  rememberDevice: false,
  showWelcomeBackModal: false,
  affiliationURLParams: null,
};

interface SetLoginDestinationProps {
  destination: string;
  state?: any;
}

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    loginSuccessNoOTP: (state) => state,
    smsOtpSuccess: (state, action: PayloadAction<boolean>) => state,
    loginSuccess: (state) => state,
    rememberDeviceSelected: (state, action: PayloadAction<boolean>) => {
      state.rememberDevice = action.payload;
    },
    navigateToLoginDestination: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.destination = action?.payload || state.destination;
    },
    setLoginDestination: (
      state,
      action: PayloadAction<SetLoginDestinationProps | undefined>,
    ) => {
      state.destination = action?.payload?.destination || state.destination;
      state.destinationState = action?.payload?.state || state.destinationState;
    },
    setAffiliationURLParams: (
      state,
      action: PayloadAction<AffiliationURLParams | null>,
    ) => {
      state.affiliationURLParams = action.payload;
    },
    setShouldShowWelcomeBackModal: (state, action: PayloadAction<boolean>) => {
      state.showWelcomeBackModal = action.payload;
    },
    noop: (state) => state,
  },
});

interface AffiliationURLParams {
  affiliate: string;
  affiliateUserIdentifier: string;
}

export interface LoginState {
  destination: string;
  destinationState: any;
  rememberDevice: boolean;
  showWelcomeBackModal: boolean;
  affiliationURLParams: AffiliationURLParams | null;
}

export interface LoginAction {
  email: string;
  password: string;
}

export interface LoginActionError {
  error?: any;
}

export const loginActions = loginSlice.actions;
export default loginSlice.reducer;
