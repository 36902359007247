import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { Alert } from 'components';
import { Paragraph } from 'components/TypographyTw';

export const ErrorAlert = ({
  icon,
  message,
}: {
  icon?: ReactNode;
  message?: string;
}) => {
  const intl = useIntl();

  return (
    <Alert
      alignTop
      className="bg-danger-500"
      icon={icon ?? <KDSIcons.Icons.HelpCircle className="mt-1 text-white" />}
    >
      <Box>
        {message ? (
          <Paragraph className="mb-0 text-white">{message}</Paragraph>
        ) : (
          <>
            <Paragraph className="text-white mb-0">
              {intl.formatMessage({
                id: 'TiersChange.Modal.SomethingWrong',
                defaultMessage: 'Something went wrong.',
              })}
            </Paragraph>
            <Paragraph className="mb-0 text-white">
              {intl.formatMessage({
                id: 'TiersChange.Modal.TryAgain',
                defaultMessage: 'Please try again later.',
              })}
            </Paragraph>
          </>
        )}
      </Box>
    </Alert>
  );
};
