import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetHISAStatusQuery } from 'apis/hisa';
import { useGetReferralsRewardsQuery } from 'apis/referralApi';
import { useGetTiersQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { selectAuthIsAuthenticated } from 'auth/store/selectors';
import { selectProfileVerified } from 'profile/store/selectors';
import { selectIsPaidTier } from 'tiers/store/selectors';
import { getTierManagementUrl, isTrialEligible } from 'tiers/utils/helpers';

import { usePagePermissions } from '../auth/hooks/usePagePermissions';

/**
 * A MenuPath object must have either a path or an onClick to create a valid menu item
 */
interface MenuPath {
  name: string;
  icon: any;
  path?: string;
  new?: boolean;
  onClick?: () => void;
  chipMessage?: string;
  component?: React.ReactElement;
}

export const usePathList = () => {
  let result: MenuPath[] = [];

  const intl = useIntl();
  const pagePermissions = usePagePermissions();

  // transactions/home
  result.push({
    name: intl.formatMessage({
      id: 'TemplateSideMenu.Spending',
      defaultMessage: 'Spending',
    }),
    path: '/transactions',
    icon: KDSIcons.Icons.KOHOCard,
  });

  const authenticated = useSelector(selectAuthIsAuthenticated);
  const HISA = useGetHISAStatusQuery(authenticated ? undefined : skipToken);

  if (pagePermissions.canSeeSavingsPage && HISA.data) {
    let path = '/savings';
    let icon = KDSIcons.Icons.Vault;
    // check for HISA status
    if (!HISA.data.is_agreement_signed) {
      icon = KDSIcons.Icons.Interest;

      // send to either start of registration or agreement page
      // based on whether the external account has been created
      path = HISA.data.is_external_account_created
        ? '/hisa/agreement'
        : '/hisa/registration';
    }

    let page: MenuPath = {
      name: intl.formatMessage({
        id: 'TemplateSideMenu.Savings',
        defaultMessage: 'Savings',
      }),
      path,
      icon,
    };

    result.push(page);
  }

  result.push({
    name: intl.formatMessage({
      id: 'TemplateSideMenu.Cover',
      defaultMessage: 'Cover',
    }),
    path: '/cover',
    icon: KDSIcons.Icons.BillsV2,
  });

  result.push({
    name: intl.formatMessage({
      id: 'TemplateSideMenu.Credit',
      defaultMessage: 'Credit Building',
    }),
    path: '/credit-building',
    icon: KDSIcons.Icons.CreditMeter,
    new: true,
  });

  result.push({
    name: intl.formatMessage({
      id: 'TemplateSideMenu.AddMoney',
      defaultMessage: 'Add money',
    }),
    path: '/load',
    icon: KDSIcons.Icons.Add,
  });

  // send money
  if (pagePermissions.canSeeSendMoneyPage) {
    result.push({
      name: intl.formatMessage({
        id: 'TemplateSideMenu.SendMoney',
        defaultMessage: 'Send money',
      }),
      path: '/send-money',
      icon: KDSIcons.Icons.Send,
    });
  }

  const { data: tiers } = useGetTiersQuery();
  const isPaidTier = useSelector(selectIsPaidTier);
  const userVerified = !!useSelector(selectProfileVerified);

  const trialEligible = !!tiers && isTrialEligible(tiers);

  const tierManagementUrl = getTierManagementUrl({
    isPaidTier,
    userVerified,
    trialEligible,
  });

  // get a plan
  if (!isPaidTier) {
    result.push({
      name: intl.formatMessage({
        id: 'TemplateSideMenu.GetAPlan',
        defaultMessage: 'Get plan',
      }),
      path: tierManagementUrl,
      icon: KDSIcons.Icons.Tiers,
      chipMessage: trialEligible
        ? intl.formatMessage({
            id: 'TemplateSideMenu.FreeTrial',
            defaultMessage: 'Free trial',
          })
        : undefined,
    });
  }

  // manage plan
  if (isPaidTier) {
    result.push({
      name: intl.formatMessage({
        id: 'TemplateSideMenu.MyPlan',
        defaultMessage: 'My plan',
      }),
      path: '/tiers/my-plan',
      icon: KDSIcons.Icons.Tiers,
    });
  }

  const { data: referralsRewardsData, isSuccess: referralRewardsLoaded } =
    useGetReferralsRewardsQuery();

  // referral bonus
  if (pagePermissions.canSeeReferralPage && referralRewardsLoaded) {
    const cashReferral = referralsRewardsData?.rewards.find(
      (reward) => reward.reward_type === 'cash',
    );

    if (!!cashReferral) {
      result.push({
        name: intl.formatMessage(
          {
            id: 'TemplateSideMenu.ReferralCashReward',
            defaultMessage: 'Earn {value}',
          },
          {
            value: cashReferral.value,
          },
        ),
        path: '/referrals',
        icon: KDSIcons.Icons.Container,
      });
    } else {
      result.push({
        name: intl.formatMessage({
          id: 'TemplateSideMenu.ReferralDefault',
          defaultMessage: 'Earn rewards',
        }),
        path: '/referrals',
        icon: KDSIcons.Icons.Container,
      });
    }
  }

  // settings
  result.push({
    name: intl.formatMessage({
      id: 'TemplateSideMenu.Settings',
      defaultMessage: 'Settings',
    }),
    path: '/settings',
    icon: KDSIcons.Icons.Settings,
  });

  return result;
};
