import { useSelector } from 'react-redux';

import { HISAFeatureFlags } from 'hisa/hisaFeatureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectProfileVerified } from '../../profile/store/selectors';

export function usePagePermissions() {
  const isProfileVerified = useSelector(selectProfileVerified);

  return {
    canSeeSendMoneyPage: Boolean(isProfileVerified),
    canSeeReferralPage: Boolean(isProfileVerified),
    canSeeSavingsPage: useFlags()[HISAFeatureFlags.EnableOnboardingFlow],
    canSeeMyCardsPage: useFlags()['web.enable-my-cards-page'],
  };
}
