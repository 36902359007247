import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { KDSIcons } from 'assets/images/kds_icons';
import { Paragraph } from 'components/TypographyTw';
import { contactActions } from 'contact/store/contact.slice';
import { twMerge } from 'tailwind-merge';

export type IntercomChatButtonVariant = 'text';

export const IntercomChatButton = ({
  variant,
  className,
}: {
  variant?: IntercomChatButtonVariant;
  className?: string;
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(contactActions.chatButtonClicked());
  };

  const intl = useIntl();

  if (variant === 'text') {
    return (
      <button className="inline-flex items-center" onClick={handleClick}>
        <Paragraph
          className={twMerge(
            'font-bold m-0 text-base text-primary-300 hover:underline',
            'light:font-medium light:text-grey-500',
            className,
          )}
        >
          {intl.formatMessage({
            id: 'IntercomChatButton.Text',
            defaultMessage: 'Get help',
          })}
        </Paragraph>
        <KDSIcons.Icons.Chat className="ml-2 duration-300 " />
      </button>
    );
  } else {
    // icon only
    return (
      <div className={className} onClick={handleClick}>
        <KDSIcons.Icons.Chat
          className="duration-300 "
          sx={{
            fontSize: '30px',
            '&:hover, &:focus': {
              cursor: 'pointer',
              filter: 'saturate(170%)',
            },
          }}
        />
      </div>
    );
  }
};
