import * as braze from '@braze/web-sdk';
import * as Sentry from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getDeviceID } from 'libs/axios';

import { identify, track } from '../../analytics/analytics';
import { trackGaIds } from '../../analytics/ga';
import { getUtmsFromSession } from '../../analytics/utm';
import {
  FingerprintEventTypes,
  createFingerprints,
} from '../../fingerprints/fingerprints';
import { intercomUpdate } from '../../intercom/intercom';
import { buildIntercomProfileTraits } from '../buildIntercomUserAttributes';
import { buildUserProfileTraits } from '../buildUserRegisteredTraits';
import { Profile } from '../models/profile';
import { profileActions } from './slice';

export interface ProfileResponse {
  profile: Profile;
}

const getProfileEpic = (action$) => {
  return action$.pipe(
    ofType(profileActions.getProfileRequest),
    switchMap(() => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}user/profile`),
      ).pipe(
        map((response: AxiosResponse<ProfileResponse>) =>
          profileActions.getProfileSuccess(response.data.profile),
        ),
        catchError(() => {
          return of(profileActions.getProfileError());
        }),
      );
    }),
  );
};

const getProfileWithEventsEpic = (action$) => {
  return action$.pipe(
    ofType(profileActions.getProfileRequestWithEvents),
    switchMap(({ payload }) => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}user/profile`),
      ).pipe(
        map((response: AxiosResponse<ProfileResponse>) =>
          profileActions.getProfileSuccessWithEvents({
            profile: response.data.profile,
            removeProfileProperties: payload,
          }),
        ),
        catchError(() => {
          return of(profileActions.getProfileError());
        }),
      );
    }),
  );
};

const getProfileSuccessWithEventsEpic = (action$, store) => {
  return action$.pipe(
    ofType(profileActions.getProfileSuccessWithEvents),
    map(({ payload }: any) => {
      const profile = store.value.profile.data;
      if (!profile) {
        return profileActions.noop();
      }

      const profileTraits = buildUserProfileTraits(profile);
      const intercomProfileTraits = buildIntercomProfileTraits(profile);

      identify(
        profile.reference_identifier,
        payload.removeProfileProperties
          ? { hashId: profileTraits.hashId }
          : profileTraits,
      );
      trackGaIds(profile.reference_identifier);
      track({ event: 'User Logged In' });

      createFingerprints(
        getDeviceID(),
        FingerprintEventTypes.LoggedIn,
        store.value.auth.loginType,
        store.value.auth.mfaOption,
      );
      intercomUpdate({ ...intercomProfileTraits, ...getUtmsFromSession() });
      setExternalServicesById(profile.reference_identifier);

      return profileActions.noop();
    }),
  );
};

const getProfileSuccessEpic = (action$, store) => {
  return action$.pipe(
    ofType(profileActions.getProfileSuccess),
    map(() => {
      const profile = store.value.profile.data;
      if (!profile) {
        return profileActions.noop();
      }
      const intercomProfileTraits = buildIntercomProfileTraits(profile);
      intercomUpdate(intercomProfileTraits);
      setExternalServicesById(profile.reference_identifier);

      return profileActions.noop();
    }),
  );
};

// Helper methods
const setExternalServicesById = (id: string): void => {
  braze.changeUser(id);
  braze.requestPushPermission();
  braze.requestContentCardsRefresh();

  // For cards that get delivered on Session Start, they will not show up until the second session
  // unless we add another refresh request after 10 seconds
  setTimeout(() => {
    braze.requestContentCardsRefresh();
  }, 10000);

  // IMPORTANT: `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
  braze.openSession();

  Sentry.configureScope((scope) => {
    scope.setUser({
      id,
    });
  });
};

const exportedArray = [
  getProfileEpic,
  getProfileSuccessEpic,
  getProfileSuccessWithEventsEpic,
  getProfileWithEventsEpic,
];

// export for testing
export {
  getProfileEpic,
  getProfileSuccessEpic,
  getProfileSuccessWithEventsEpic,
  getProfileWithEventsEpic,
};
export default exportedArray;
