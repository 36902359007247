import { HttpResponse, http } from 'msw';

const authHandlers = [
  http.get(/\/1\.0\/auth\/delegate-token\?token_id=.*/, () => {
    return new HttpResponse(null, {
      status: 201,
      headers: {
        'Set-Cookie':
          'sessionToken=MOCKED_USER_DELEGATION_TOKEN; Path=/; HttpOnly; Secure; SameSite=Lax',
        credentials: 'include',
      },
    });
  }),
];

export default authHandlers;
