import { useSelector } from 'react-redux';

import { selectUserDelegation } from 'auth/store/selectors';
import { selectUserRefID } from 'profile/store/selectors';

const UserDelegationBanner = () => {
  const isUserDelegation = useSelector(selectUserDelegation);
  const userId = useSelector(selectUserRefID);

  if (!isUserDelegation) return <></>;

  return (
    <div className="w-full text-center bg-warning-75 p-2">
      ⚠️ You are in <strong>User View mode</strong>.{' '}
      {userId && (
        <>
          Current User is <strong>{userId}</strong>.{' '}
        </>
      )}
      Please be careful
    </div>
  );
};

export default UserDelegationBanner;
