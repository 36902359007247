import { useIntl } from 'react-intl';

import { Chip } from '@material-ui/core';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Affiliate, useGetAffiliatesQuery } from 'apis/affiliates';
import { ICurrentBenefit } from 'apis/tiers';
import clsx from 'clsx';
import { BodyXs } from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { twMerge } from 'tailwind-merge';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { isOnFreeTrial, tierIsCancelled } from 'tiers/utils/helpers';

const FreeChip = ({ daysRemaining }: { daysRemaining: string }) => {
  const intl = useIntl();

  return (
    <Chip
      classes={{
        root: twMerge(
          'h-6 px-2 text-xs font-bold bg-primary-50 text-primary-300',
          'legacy:bg-tiers-blue-50 legacy:text-tiers-blue-400',
        ),
        label: 'px-0',
      }}
      data-cy="my-plan-trial-chip"
      label={intl.formatMessage(
        {
          defaultMessage: '{daysRemaining} days free',
          id: 'MyPlan.TrialDays',
        },
        { daysRemaining },
      )}
    />
  );
};

const CancellationChip = () => {
  const intl = useIntl();
  return (
    <Chip
      classes={{
        root: 'h-6 px-2 text-xs font-bold bg-danger-50 text-danger-300',
        label: 'px-0',
      }}
      data-cy="my-plan-cancellation-chip"
      label={intl.formatMessage({
        defaultMessage: 'Pending cancellation',
        id: 'MyPlan.PendingCancellation',
      })}
    />
  );
};

const useGetDateLabel = ({
  isCancelling,
  downgradeTierName,
}: {
  isCancelling: boolean;
  downgradeTierName: string;
}) => {
  const intl = useIntl();
  if (isCancelling)
    return intl.formatMessage({ id: 'MyPlan.Ends', defaultMessage: 'Ending:' });

  if (downgradeTierName)
    return intl.formatMessage(
      {
        id: 'MyPlan.StartedNewTier',
        defaultMessage: '{tierName} starting:',
      },
      { tierName: downgradeTierName },
    );

  return intl.formatMessage({ id: 'MyPlan.Renews', defaultMessage: 'Renews:' });
};

const RenewalDate = ({
  date,
  downgradeTierName,
  isCancelling,
  isEverything,
}: {
  date: Date;
  downgradeTierName: string;
  isCancelling: boolean;
  isEverything: boolean;
}) => {
  const intl = useIntl();
  const dateLabel = useGetDateLabel({ isCancelling, downgradeTierName });
  return (
    <BodyXs
      data-cy="my-plan-renewal-label"
      className={clsx(
        'text-xs legacy:font-semibold',
        'legacy:[&_strong]:font-black light:text-white',
        isEverything ? 'text-grey-400' : 'text-white light:text-grey-400',
      )}
    >
      <strong>{dateLabel}</strong>{' '}
      {intl.formatDate(date, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}
    </BodyXs>
  );
};

type Props = {
  currentTier: ICurrentBenefit;
};

export const TierRenewal = ({ currentTier }: Props) => {
  const { getTierName } = useTiersData();

  const isAffiliateRegistrationEnabled =
    useFlags()[RegistrationFeatureFlags.EnableAffiliateRegistration];
  const { data: affiliateData } = useGetAffiliatesQuery(
    isAffiliateRegistrationEnabled ? undefined : skipToken,
  );

  const downgradeTierName = currentTier.delayed_downgrade?.benefit_group?.name;
  const localizedDowngradeTierName = downgradeTierName
    ? getTierName(downgradeTierName)
    : '';
  const renewalDate = currentTier.next_payment_date;
  const onFreeTrial = isOnFreeTrial(currentTier);
  const pendingCancellation = tierIsCancelled(currentTier);

  const isEverything = currentTier.benefit_group?.name === 'Everything';

  const showChip =
    onFreeTrial &&
    !pendingCancellation &&
    !affiliateData?.affiliations.some(
      (affiliation) => affiliation.affiliate === Affiliate.CanadaPostEmployee,
    );

  return (
    <div className="flex gap-2 items-center">
      {showChip && (
        <FreeChip daysRemaining={currentTier.trial_days_remaining} />
      )}

      {pendingCancellation && <CancellationChip />}

      {renewalDate && (
        <RenewalDate
          date={renewalDate}
          downgradeTierName={localizedDowngradeTierName}
          isEverything={isEverything}
          isCancelling={pendingCancellation}
        />
      )}
    </div>
  );
};
