/** @jsxImportSource theme-ui */
import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import ButtonUnstyledTw from './ButtonUnstyledTw';
import { Paragraph } from './TypographyTw';

export const ProgressBar = ({
  currentStep,
  totalSteps,
  showSteps = true,
  showBackButton = false,
  showBackButtonMobile = false,
  onBack,
}: {
  currentStep: number;
  totalSteps: number;
  showSteps?: boolean;
  showBackButton?: boolean;
  showBackButtonMobile?: boolean;
  onBack?: Function;
}) => {
  const intl = useIntl();
  const backButton = (
    <ButtonUnstyledTw
      onClick={() => {
        window.history.back();
        onBack && onBack();
      }}
      aria-label={intl.formatMessage({
        id: 'Global.Button.Back',
        defaultMessage: 'Back',
      })}
      className={twMerge(
        clsx(
          'box-content w-6 h-6 block md:block mb-2',
          showBackButtonMobile && 'hidden mb:block',
        ),
      )}
    >
      <KDSIcons.Icons.ChevronLeft className="w-full h-full" />
    </ButtonUnstyledTw>
  );

  return (
    <div className="mb-2">
      {showBackButton && backButton}
      {showSteps && (
        <Paragraph className="text-grey-400 text-sm mb-4">
          {intl.formatMessage({
            id: 'ProgressBar.Title',
            defaultMessage: 'Step',
          })}{' '}
          {currentStep}/{totalSteps}
        </Paragraph>
      )}
      <div className="h-1 w-full bg-grey-75 rounded-lg">
        <div
          className="h-inherit rounded-lg w-[var(--progress-width)] bg-[linear-gradient(90deg,#892CFF_0%,#6A22C9_100%)]"
          style={
            {
              '--progress-width':
                currentStep === totalSteps && showSteps
                  ? `${(currentStep / totalSteps) * 100 - 5}%`
                  : `${(currentStep / totalSteps) * 100}%`,
            } as CSSProperties
          }
        ></div>
      </div>
    </div>
  );
};
