import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';

export type OnfidoApplicantReportType =
  | 'document'
  | 'face_video'
  | 'face_motion';

export interface OnfidoApplicantResponse {
  data: {
    token: string;
    report_types: OnfidoApplicantReportType[];
  };
}

export interface OnfidoResultResponse {
  data: {
    next_step: string;
  };
  docs_type: string[];
  next_step: GetCheckResultNextStep;
}

export enum CreateApplicantErrorCodes {
  ApplicantAlreadyCleared = 101,
  CheckInProgress = 102,
}

export enum CreateCheckErrorCodes {
  CheckInProgress = 422,
}

export enum GetCheckResultErrorCode {
  CheckInProgress = 408,
}

export type GetCheckResultNextStep = 'additional' | 'failure' | 'retry';

export interface OnfidoState {
  data: OnfidoApplicantResponse['data'] | null;
  retries: number;
  timer: number[];
  loadable: Loadable;
  sourceStart: string;
  completedSuccessfully: boolean;
}

const initialState: OnfidoState = {
  data: null,
  retries: 0,
  timer: [0, 5, 10, 15, 30, 60].map((x) => x * 1000),
  loadable: createDefaultLoadable(),
  sourceStart: 'none',
  completedSuccessfully: false,
};

const onfidoSlice = createSlice({
  name: 'onfido',
  initialState: initialState,
  reducers: {
    createApplicantRequest: (state) => {
      state.loadable = createLoadingLoadable();
      state.completedSuccessfully = false;
    },
    createApplicantResponse: (
      state,
      {
        payload: { response },
      }: PayloadAction<{
        response: OnfidoApplicantResponse;
      }>,
    ) => {
      state.loadable = createSuccessLoadable();
      state.data = response.data;
    },
    createApplicantError: (state, action: PayloadAction<{ error: any }>) => {
      state.loadable = createErrorLoadable(
        action.payload.error.response?.status || 'unable to create check',
      );
    },
    createCheckRequest: (state) => {
      state.loadable = createLoadingLoadable();
    },
    createCheckResponse: (state) => {
      state.loadable = createSuccessLoadable();
    },
    createCheckError: (state, action: PayloadAction<{ error: any }>) => {
      state.loadable = createErrorLoadable(
        action.payload.error.response?.status || 'unable to create check',
      );
    },
    getCheckResultRequest: (state) => {
      state.loadable = createLoadingLoadable();
    },
    getCheckResultResponse: (
      state,
      action: PayloadAction<OnfidoResultResponse>,
    ) => {
      state.loadable = createSuccessLoadable();
      state.completedSuccessfully = true;
    },
    getCheckResultError: (state) => {
      state.loadable = createErrorLoadable('unable to get check result');
    },
    retryGetCheckResult: (state) => {
      state.retries = state.retries + 1;
      state.loadable = createLoadingLoadable();
    },
    setSourceStart: (state, action: PayloadAction<string>) => {
      state.sourceStart = action.payload;
    },
    initializeOnfido: (state, _: PayloadAction<{ locale: string }>) => state,
    noop: (state) => state,
  },
});

export const onfidoActions = onfidoSlice.actions;
export default onfidoSlice.reducer;
