import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { selectTheme } from 'appState/appState.slice';
// import { KDSIcons } from 'assets/images/kds_icons';
import axios from 'axios';
import { ButtonTw } from 'components/ButtonTw';
import CircularProgress from 'components/CircularProgress';
import { Paragraph, TitleMedium } from 'components/TypographyTw';

import { track } from '../analytics/analytics';
import phonesImage from '../assets/images/general/phone@2x.png';
import {
  Card,
  FooterImageWrapper,
  LayoutBasic,
} from '../components/LayoutBasic';

export const EmailVerificationLanding = (props: RouteComponentProps) => {
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  return (
    <LayoutBasic pageName="Email confirmation" className="bg-white">
      <Card className="mb-16 light:[&>*]:text-center">
        <Main />
      </Card>
      {!isLight && (
        <FooterImageWrapper className="before:bg-secondary-200">
          <img
            src={phonesImage}
            alt=""
            className="w-[500px] max-w-full relative z-[2]"
          />
        </FooterImageWrapper>
      )}
    </LayoutBasic>
  );
};

export const Main = () => {
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';
  const [screen, setScreen] = useState<'pending' | 'success' | 'failed'>(
    'pending',
  );
  const [emailStatus, setEmailStatus] = useState<
    'not sent' | 'pending' | 'sent' | 'failed'
  >('not sent');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [loaded, setLoaded] = useState(false);
  const intl = useIntl();

  const resendVerificationEmail = () => {
    setEmailStatus('pending');
    axios
      .post(`${import.meta.env.VITE_GATEWAY_API}email/request`, { email })
      .then(() => {
        setEmailStatus('sent');
        track({ event: 'email confirmation email sent' });
      })
      .catch(() => {
        setEmailStatus('failed');
        track({ event: 'email confirmation email resending failed' });
      });
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      // support legacy ? urls and the preferred #
      const paramsDelimeter = window.location.href.includes('?') ? '?' : '#';
      const params = new URLSearchParams(
        window.location.href.split(paramsDelimeter).pop(),
      );
      const token = params.get('token');
      const email = params.get('email');

      setToken(token || '');
      setEmail(email || '');

      axios
        .post(`${import.meta.env.VITE_GATEWAY_API}email/verify`, {
          token,
          email,
        })
        .then(() => {
          setScreen('success');
          track({ event: 'email verification success' });
        })
        .catch(() => {
          setScreen('failed');
          track({ event: 'Email verification failed' });
        });
    }
  }, [token, loaded]);

  const imgUrl = `${import.meta.env.VITE_STATIC_URL}/email-confirmed.png`;

  switch (screen) {
    case 'pending':
      return <CircularProgress />;

    case 'success':
      return (
        <>
          {/* TODO: replace asset
          <div className="light:flex light:justify-center light:mb-5">
            <div className="light:rounded-full light:bg-success-75 light:p-3">
              <icons.Spot.Success className="legacy:mb-4 light:[&>path]:stroke-success-300" />
            </div>
          </div>
          */}
          <TitleMedium className="light:text-grey-500">
            {intl.formatMessage({
              id: 'EmailVerificationLanding.SuccessTitle',
              defaultMessage: 'Thanks for confirming your email',
            })}
          </TitleMedium>
          <Paragraph className="light:text-grey-400">
            {intl.formatMessage({
              id: 'EmailVerificationLanding.SuccessContent',
              defaultMessage:
                'Get started by adding funds within the KOHO mobile app! Shop online with your Virtual Card while you wait for your physical card to arrive in the mail.',
            })}
          </Paragraph>
          {isLight && <img src={imgUrl} alt="Email confirmed" />}
        </>
      );

    case 'failed':
      return (
        <>
          {/* TODO: replace asset
          <KDSIcons.Spot.Stop className="mb-4" />
          */}
          <TitleMedium>
            {intl.formatMessage({
              id: 'EmailVerificationLanding.FailedTitle',
              defaultMessage: 'Sorry, this verification link expired.',
            })}
          </TitleMedium>

          <Paragraph>
            {intl.formatMessage({
              id: 'EmailVerificationLanding.FailedContent',
              defaultMessage:
                'For security purposes, this verification link has expired. Resend the link to confirm your email!',
            })}
          </Paragraph>

          <ButtonTw
            type="button"
            trackName="Resend device verification email"
            onClick={resendVerificationEmail}
          >
            {(() => {
              switch (emailStatus) {
                case 'not sent':
                  return intl.formatMessage({
                    id: 'EmailVerificationLanding.NotSentButton',
                    defaultMessage: 'Resend email',
                  });
                case 'pending':
                  return <CircularProgress />;
                case 'sent':
                  return intl.formatMessage({
                    id: 'EmailVerificationLanding.SentButton',
                    defaultMessage: 'Email sent, check your inbox.',
                  });
                case 'failed':
                  return intl.formatMessage({
                    id: 'EmailVerificationLanding.FailedButton',
                    defaultMessage:
                      'Error sending email - check your connection?',
                  });
              }
            })()}
          </ButtonTw>
        </>
      );

    default:
      return null;
  }
};
