import { track } from 'analytics/analytics';
import { loginActions } from 'login/store/slice';

import { rootApi } from './rootApi';

export enum Affiliate {
  CanadaPostEmployee = 'CANADAPOSTEMPLOYEE',
  CanadaPostGeneral = 'CANADAPOSTGENERAL',
  XTM = 'XTM',
}

export const CANADA_POST_AFFILIATES = [
  Affiliate.CanadaPostEmployee,
  Affiliate.CanadaPostGeneral,
];
export function isCanadaPostAffiliate(affiliations?: Affiliation[]) {
  return affiliations?.some((affiliation) =>
    CANADA_POST_AFFILIATES.includes(affiliation.affiliate),
  );
}

export function isXTMAffiliate(affiliations?: Affiliation[]) {
  return (
    affiliations?.some(
      (affiliation) => affiliation.affiliate === Affiliate.XTM,
    ) || false
  );
}

export enum AffiliateAction {
  CustomerSupport = 'customer_support',
  ManualAccountEntry = 'manual_account_entry',
  VerifyIdentity = 'verify_identity',
}

export interface Affiliation {
  action?: AffiliateAction;
  affiliate: Affiliate;
  onboarding_status?: 'pending' | 'active' | 'failed';
  updated_at: string;
}

export interface AffiliateResponse {
  /**
   * @deprecated Use 'affiliations' instead
   */
  affiliates: Affiliate[];
  referral_code?: string;
  affiliations: Affiliation[];
}

interface PostAffiliateWithAffiliateUserIdentifierParams {
  affiliate: Affiliate;
  affiliate_user_identifier: string;
}

export const affiliatesApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    postReferralAffiliate: build.mutation<
      AffiliateResponse,
      { referral_code: string }
    >({
      query: (request) => ({
        url: '/2.0/users/affiliates/registration',
        method: 'POST',
        body: request,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Post Referral Affiliate Failed' });
        }
      },
      invalidatesTags: ['Affiliates', 'AffiliatesWithFailed'],
    }),
    postAffiliateWithAffiliateUserIdentifier: build.mutation<
      AffiliateResponse,
      PostAffiliateWithAffiliateUserIdentifierParams
    >({
      query: (request) => ({
        url: `/2.0/users/affiliates/${request.affiliate_user_identifier}`,
        method: 'POST',
        body: {
          affiliate: request.affiliate,
        },
      }),
      invalidatesTags: ['Affiliates', 'AffiliatesWithFailed'],
    }),
    getAffiliates: build.query<AffiliateResponse, void>({
      query: () => ({
        url: '/2.0/users/affiliates',
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Get Affiliates Failed' });
        }
      },
      transformResponse: (response: AffiliateResponse) => {
        return {
          ...response,
          affiliations: response.affiliations.filter(
            (affiliation) => affiliation.onboarding_status !== 'failed',
          ),
        };
      },
      providesTags: ['Affiliates'],
    }),
    getAffiliatesWithFailed: build.query<AffiliateResponse, void>({
      query: () => ({
        url: '/2.0/users/affiliates',
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Get Affiliates Failed' });
        }
      },
      providesTags: ['AffiliatesWithFailed'],
    }),
    updateAffiliateConsentStatus: build.mutation<
      void,
      { affiliate: Affiliate; optedIn: boolean }
    >({
      query: ({ affiliate, optedIn }) => ({
        url: `/2.0/users/affiliates/consent`,
        method: 'PUT',
        body: {
          affiliate,
          opted_in: optedIn,
        },
      }),
    }),
    postXTMAffiliation: build.mutation<AffiliateResponse, number>({
      query: (affiliate_user_identifier) => ({
        url: `users/2.0/affiliates/xtm/existing`,
        method: 'POST',
        body: {
          affiliate_user_identifier,
        },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(loginActions.setShouldShowWelcomeBackModal(true));
          dispatch(loginActions.setAffiliationURLParams(null));
        } catch {
          track({ event: 'Post XTM Affiliation Failed' });
          dispatch(loginActions.setAffiliationURLParams(null));
        }
      },
      invalidatesTags: ['Affiliates', 'AffiliatesWithFailed'],
    }),
  }),
});

export const {
  usePostReferralAffiliateMutation,
  useGetAffiliatesQuery,
  useGetAffiliatesWithFailedQuery,
  useLazyGetAffiliatesQuery,
  useUpdateAffiliateConsentStatusMutation,
  usePostAffiliateWithAffiliateUserIdentifierMutation,
  usePostXTMAffiliationMutation,
} = affiliatesApi;
