import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';

import { getDeviceID } from 'libs/axios';

const baseQueryDefault = async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: import.meta.env.VITE_GATEWAY_BASE_API,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      headers.set(
        'x-koho-app-version',
        `${import.meta.env.VITE_APP_VERSION_HEADER}`,
      );
      headers.set(
        'x-koho-device-platform',
        `${import.meta.env.VITE_DEVICE_PLATFORM_HEADER}`,
      );
      headers.set(
        'X-Organization',
        `${import.meta.env.VITE_DEVICE_ORGANIZATION_HEADER}`,
      );
      headers.set('x-device-id', getDeviceID());

      const fetchedAccounts = state.accounts.accountsAll.accounts;

      if (fetchedAccounts) {
        const personalAccount = fetchedAccounts.find(
          (account) => account.category === 'personal',
        );

        if (personalAccount) {
          headers.set('x-account-id', personalAccount.group_id);
        }
      }
      headers.set('Accept-Language', state.appState.currentLanguage);

      headers.set(
        'X-Koho-Bypass-OTP-Authorization',
        state.auth.mfaOption === 'bypass' ? 'true' : 'false',
      );
    },

    credentials: 'include',
  })(args, api, extraOptions);
  return result;
};

export default baseQueryDefault;
