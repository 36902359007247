import { CSSProperties } from 'react';

import { Paragraph } from 'components/TypographyTw';

import { ColourWheelValues } from '../../models/ColorWheelValues';
import { Recipient } from '../../models/Recipient';

export const RecipientAvatar = ({ recipient }: { recipient: Recipient }) => {
  return (
    <div
      className="bg-[var(--avatar-color)] w-[40px] h-[40px] flex justify-center items-center rounded-xl"
      style={
        {
          '--avatar-color':
            ColourWheelValues[recipient.initials.toLowerCase()[0]],
        } as CSSProperties
      }
    >
      <Paragraph className="text-white font-bold mb-0">
        {recipient.initials.toUpperCase()}
      </Paragraph>
    </div>
  );
};
