import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { navigate } from '@reach/router';
import { Language } from 'appState/appState.slice';
import { LocaleContext } from 'components';
// components
import { ButtonTw } from 'components/ButtonTw';
import { Modal } from 'components/Modal';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { addDays, format } from 'date-fns';
import { loginActions } from 'login/store/slice';
// types
import type { Image } from 'types/Image';

const imageUrl = import.meta.env.VITE_STATIC_URL + '/modals/welcome.png';

const M1ContestModal = ({ open = false }: { open: boolean }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { locale } = useContext(LocaleContext);

  const [localOpen, setLocalOpen] = useState(open);

  const handleClose = () => {
    setLocalOpen(!localOpen);
    dispatch(loginActions.setShouldShowWelcomeBackModal(false));
  };

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const image: Image = {
    displayHeights: ['160px', '350px'],
    displayWidths: ['370px', '600px'],
    url: imageUrl,
  };

  return (
    <Modal
      data-cy="contest-modal"
      image={image}
      open={localOpen}
      onClose={handleClose}
      classes={{
        paper: 'bg-primary-500',
        root: '[&_.MuiSvgIcon-root]:text-white text-center [&_.MuiDialogContent-root]:pb-5',
      }}
      absolutePositioning={false}
      maxWidth="sm"
    >
      <TitleLarge className="text-secondary-75">
        {intl.formatMessage({
          id: 'M1ContestModal.Title',
          defaultMessage: 'The Growth Challenge',
        })}
      </TitleLarge>
      <Paragraph
        className="text-white mb-8"
        data-cy="contest-modal-description"
      >
        {intl.formatMessage(
          {
            id: 'M1ContestModal.Description',
            defaultMessage: `<p>New year, new goals.</p>
                <p>Win up to <strong>$1000</strong> or <strong>KOHO prizes</strong>, helping you earn more and stress less in 2025.</p>
                <p><strong>How to join the challenge</strong><br></br>
                1. Add and spend at least $100<br></br>
                2. And sign up for 4% Interest</p>
                <p>Complete both tasks by {date} and you could win up to $1000 or KOHO prizes!</p>
            `,
          },
          {
            br: () => <br />,
            p: (content) => <p className="mt-4 mb-4">{content}</p>,
            strong: (content) => <strong>{content}</strong>,
            date: format(addDays(new Date(), 14), 'MMMM d'),
          },
        )}
      </Paragraph>
      <div className="flex flex-col gap-2">
        <ButtonTw
          data-cy="contest-modal-cta-add-money"
          onClick={() => navigate('/load')}
          trackName="Modal - Contest -- Add money"
          variant={'secondaryInverted'}
        >
          {intl.formatMessage({
            id: 'M1ContestModal.CTA.AddMoney.Text',
            defaultMessage: 'Get started',
          })}
        </ButtonTw>
        <ButtonTw
          data-cy="contest-modal-cta-cancel"
          onClick={() => {
            window.open(
              locale === Language.EN
                ? 'https://www.koho.ca/legal/#GrowthChallengeTerms'
                : 'https://www.koho.ca/fr/legal/#GrowthChallengeTerms',
              '_blank',
            );
          }}
          className="text-white"
          variant={'clear'}
        >
          {intl.formatMessage({
            id: 'M1ContestModal.CTA.Cancel.Text',
            defaultMessage: 'See details',
          })}
        </ButtonTw>
      </div>
    </Modal>
  );
};

export default M1ContestModal;
