import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AUTH_OTP_TIMEOUT, AUTH_SESSION_TIMEOUT } from '../models';

const parseTimestampToInt = (timestamp: string | null) => {
  if (!timestamp) return null;
  return parseInt(timestamp);
};

const setSessionReducer = {
  reducer(state, action: PayloadAction<AuthSetSessionAction>) {
    state.timeout = action.payload.timeout;
    state.forcedLogout = false;
    state.sessionRefreshError = false;
  },
  prepare() {
    return {
      payload: {
        timeout: Date.now() + 9 * 60 * 1000, // 9 min
      },
    };
  },
};

const setOtpTimeoutReducer = {
  reducer(state, action: PayloadAction<AuthSetOtpTimeoutAction>) {
    state.otp = {
      ...state.otp,
      timeout: action.payload.timeout,
      id: null,
    };
  },
  prepare() {
    return {
      payload: {
        timeout: Date.now() + 30 * 24 * 60 * 60 * 1000, // 30 days
      },
    };
  },
};

const initialState: AuthState = {
  forcedLogout: false,
  isNewLogin: false,
  loginType: '',
  mfaOption: '',
  mfaPhoneDigits: '',
  newLoginInfo: {
    email: '',
    pass: '',
  },
  otp: {
    timeout: parseTimestampToInt(
      localStorage.getItem(AUTH_OTP_TIMEOUT)
        ? localStorage.getItem(AUTH_OTP_TIMEOUT)
        : sessionStorage.getItem(AUTH_OTP_TIMEOUT),
    ),
  },
  sessionRefreshError: false,
  timeout: parseTimestampToInt(localStorage.getItem(AUTH_SESSION_TIMEOUT)),
  userDelegation: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setSession: setSessionReducer,
    refreshSession: (state) => state,
    deleteSession: () => {
      return {
        ...initialState,
        timeout: null,
      };
    },
    refreshSessionError: (state) => {
      state.timeout = null;
      state.sessionRefreshError = true;
    },
    deleteSessionForced: (state) => {
      state.timeout = null;
      state.forcedLogout = true;
    },
    setOtpTimeout: setOtpTimeoutReducer,
    deleteOtpTimeout: (state) => {
      state.otp = {
        ...state.otp,
        timeout: null,
      };
    },
    setNewLoginInfo: (
      state,
      action: PayloadAction<{
        email: string;
        isNewLogin: boolean;
        loginType: string;
        mfaOption: string;
        mfaPhoneDigits: string;
        pass: string;
      }>,
    ) => {
      state.isNewLogin = action.payload.isNewLogin;
      state.loginType = action.payload.loginType;
      state.mfaOption = action.payload.mfaOption;
      state.mfaPhoneDigits = action.payload.mfaPhoneDigits;
      state.newLoginInfo.email = action.payload.email;
      state.newLoginInfo.pass = action.payload.pass;
    },
    deleteSensitiveInfo: (state) => {
      state.newLoginInfo.email = '';
      state.newLoginInfo.pass = '';
    },
    setLoginType: (state, action: PayloadAction<string>) => {
      state.loginType = action.payload;
    },
    setUserDelegationMode: (state) => {
      state.mfaOption = 'bypass';
      state.userDelegation = true;
    },
    noop: (state) => state,
  },
});

export interface AuthState {
  isNewLogin: boolean;
  forcedLogout: boolean;
  loginType: string;
  mfaPhoneDigits: string;
  mfaOption: string;
  newLoginInfo: {
    email: string;
    pass: string;
  };
  otp: {
    timeout: number | null;
  };
  sessionRefreshError: boolean;
  timeout: number | null;
  userDelegation: boolean;
}

export interface AuthSetSessionAction {
  timeout: number;
}
export interface AuthSetOtpTimeoutAction {
  timeout: number;
}

export const authActions = authSlice.actions;
export default authSlice.reducer;
