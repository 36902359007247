import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { track } from 'analytics/analytics';
import {
  IBillingPaymentMethod,
  useGetPreferredBillingMethodsQuery,
  usePostPreferredMethodMutation,
} from 'apis/billing';
import { IBenefitGroup, IPlan } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { selectBalancePersonalSpendable } from 'balance/store/selectors';
import { balanceActions } from 'balance/store/slice';
import { PaymentMethodOptions } from 'billing/components/paymentMethodOptions';
import { BillingProducts } from 'billing/models/BillingProducts';
import { BillingPaymentMethodOrganization } from 'billing/models/PaymentMethodOrganization';
import { LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { StatelessDrawerModal } from 'components/DrawerModal';
import { LoadingModal } from 'components/Loading/Modal';
import { Paragraph, ParagraphSmall } from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Money } from 'models';
import { useChangeTier } from 'tiers/hooks/useChangeTier';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';

import { ErrorAlert } from '../ErrorAlert';
import { PlanCard } from '../PlanCard';
import { Steps } from '../tierChangeSteps';
import { FreeTrialSteps } from './FreeTrialSteps';
import { PaymentMethod } from './PaymentMethod';
import { UpgradeSteps } from './UpgradeSteps';

const useGetButtonText = ({ isFree }: { isFree: boolean }) => {
  const intl = useIntl();
  if (isFree)
    return intl.formatMessage({
      id: 'TiersUpgrade.Modal.StartTrial',
      defaultMessage: 'Start my free trial',
    });
  return intl.formatMessage({
    id: 'TiersUpgrade.Modal.StartMyUpgrade',
    defaultMessage: 'Start my upgrade',
  });
};

const ConfirmChange = ({
  disabled,
  handleConfirm,
  isFree,
}: {
  disabled: boolean;
  handleConfirm: () => void;
  isFree: boolean;
}) => {
  const intl = useIntl();
  const buttonText = useGetButtonText({ isFree });
  return (
    <Box>
      <ButtonTw
        data-cy="confirm-tier-change"
        className="mb-4 legacy:bg-tiers-blue-300"
        disabled={disabled}
        onClick={() => {
          track({
            event: 'Clicked',
            properties: {
              name: 'tiers-confirm-modal-confirm',
              isTrial: isFree,
            },
          });
          handleConfirm();
        }}
      >
        {buttonText}
      </ButtonTw>
      <ParagraphSmall className="text-grey-400 text-center">
        {intl.formatMessage({
          id: 'TiersUpgrade.Modal.CancelAnyTime',
          defaultMessage: 'Cancel any time.',
        })}
      </ParagraphSmall>
    </Box>
  );
};

type Props = {
  isFree: boolean;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  selectedPlan: IPlan;
  selectedTier: IBenefitGroup;
};

export const UpgradeConfirmationModal = ({
  isFree,
  onClose,
  onConfirm,
  open,
  selectedPlan,
  selectedTier,
}: Props) => {
  const context = useContext(LocaleContext);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { getSavingPerMonth } = useTiersData();
  const balance = useSelector(selectBalancePersonalSpendable);
  const [currentlySelectedBillingMethod, setCurrentSelectedBillingMethod] =
    useState<IBillingPaymentMethod | null>(null);

  const isTiersStripePaymentEnabled =
    useFlags()[TiersFeatureFlags.EnableTiersStripePayment];

  const [
    setBillingMethod,
    {
      isError: setBillingMethodFailed,
      error: setBillingMethodError,
      isLoading: isBillingMethodLoading,
    },
  ] = usePostPreferredMethodMutation();

  const { data: preferredBillingMethods } =
    useGetPreferredBillingMethodsQuery();
  const tiersPreferredBillingMethod = preferredBillingMethods?.find(
    (method) => method.product === BillingProducts.Tiers,
  );

  useEffect(() => {
    dispatch(balanceActions.getBalanceRequest());
  }, [dispatch, context.apiLanguageHeader]);

  const {
    handleSubscription,
    activeTierSuccess,
    activeTierError,
    isSubscriptionLoading,
    subscriptionFailed,
  } = useChangeTier({ selectedTier, selectedPlan, onConfirm });

  const planCost = new Money(selectedPlan?.amount_cents ?? 0);

  const localizedBalance = new Money(balance || '$0.00', context.locale).format(
    'pretty',
  );
  const accountBalance = parseFloat(balance?.replace('$', '').trim() ?? '');
  const notEnoughFunds =
    !isFree &&
    (Number.isNaN(accountBalance) || planCost.gt(accountBalance * 100));

  const averageMonthlySavings = getSavingPerMonth(
    selectedTier.average_savings_per_month,
  );

  const handleSetBillingMethodAndSubscription = useCallback(async () => {
    if (currentlySelectedBillingMethod) {
      await setBillingMethod({
        product: BillingProducts.Tiers,
        payment_method_id: currentlySelectedBillingMethod.id,
        payment_method_org: currentlySelectedBillingMethod.organization,
      });
    }

    handleSubscription();
  }, [currentlySelectedBillingMethod, handleSubscription, setBillingMethod]);

  const kohoIsPaymentMethod =
    tiersPreferredBillingMethod?.organization ===
    BillingPaymentMethodOrganization.Koho;
  const cannotPayForUpgrade =
    (!tiersPreferredBillingMethod || kohoIsPaymentMethod) &&
    notEnoughFunds &&
    !isFree;

  return (
    <>
      <StatelessDrawerModal
        modalProps={{ scroll: 'body', maxWidth: false }}
        modalClasses={{
          root: 'w-auto p-2',
        }}
        isOpen={open}
        closeModal={() => {
          track({
            event: 'Clicked',
            properties: { name: 'tiers-confirm-modal-cancel', isTrial: isFree },
          });
          onClose();
        }}
        title={intl.formatMessage({
          id: 'TiersUpgrade.Modal.Title',
          defaultMessage: 'Confirm my upgrade',
        })}
        content={() => (
          <div className="flex flex-col gap-6">
            <Paragraph className="mb-0 text-grey-400">
              {averageMonthlySavings}
            </Paragraph>
            {(subscriptionFailed || activeTierError) && <ErrorAlert />}
            <PlanCard tier={selectedTier} plan={selectedPlan} isFree={isFree} />
            <Steps selectedTier={selectedTier} selectedPlan={selectedPlan}>
              {isFree
                ? ({ tier, plan }) => <FreeTrialSteps tier={tier} plan={plan} />
                : ({ plan, changeEstimate, activeTier }) => (
                    <UpgradeSteps
                      plan={plan}
                      changeEstimate={changeEstimate}
                      activeTier={activeTier}
                    />
                  )}
            </Steps>
            {isTiersStripePaymentEnabled ? (
              <div className="flex flex-col gap-4">
                <PaymentMethodOptions
                  isProductTrialEligible={isFree}
                  minBillingAmount={planCost}
                  onChange={setCurrentSelectedBillingMethod}
                  paymentConfirmationCTA={intl.formatMessage({
                    id: 'BillingMethodsAddCardForm.SaveAndUpgrade',
                    defaultMessage: 'Save and upgrade',
                  })}
                  onAddNewCard={handleSubscription}
                  product={BillingProducts.Tiers}
                />
                {setBillingMethodFailed && (
                  <ErrorAlert
                    icon={
                      <KDSIcons.Icons.Warning className="text-white legacy:w-18 legacy:h-18 -mr-1 light:scale-[0.7]" />
                    }
                    message={
                      setBillingMethodError?.['message'] ??
                      intl.formatMessage({
                        id: 'TiersUpgrade.PaymentPreferenceFailed.DefaultMessage',
                        defaultMessage:
                          'Your payment method was declined, try adding a new one to complete your upgrade.',
                      })
                    }
                  />
                )}
              </div>
            ) : (
              <PaymentMethod
                notEnoughFunds={notEnoughFunds}
                balance={localizedBalance}
              />
            )}
            <ConfirmChange
              isFree={isFree}
              handleConfirm={handleSetBillingMethodAndSubscription}
              disabled={
                isTiersStripePaymentEnabled
                  ? cannotPayForUpgrade || !activeTierSuccess
                  : notEnoughFunds || !activeTierSuccess
              }
            />
          </div>
        )}
      />
      {(isSubscriptionLoading || isBillingMethodLoading) && <LoadingModal />}
    </>
  );
};
