import { ReactNode } from 'react';

import { Box, Collapse } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { IBenefitGroup, IPlan, useGetTiersQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { Paragraph } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';
import { theme } from 'theme';
import { TierKey } from 'tiers/models';

import { useTiersData } from '../../hooks/useTiersData';
import { ChangePlan } from '../ChangePlan';
import { Card } from './Card';
import { Features } from './Features';
import { TierCardHeader } from './TierCardHeader';

const SavingsPerYear = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid [grid-template-columns:auto_1fr] gap-x-2 legacy:bg-tiers-blue-50 bg-primary-75 rounded-lg px-4 py-3">
      <KDSIcons.Icons.Tiers
        className="legacy:text-tiers-blue-300 text-primary-350"
        color={theme.colors.blueNavy}
      />
      <Paragraph
        className={twMerge(
          'mb-0 text-sm font-bold text-primary-350 [&_span]:text-primary-200',
          'legacy:font-black legacy:text-tiers-blue-400 legacy:[&_span]:text-secondary-300',
        )}
      >
        {children}
      </Paragraph>
    </div>
  );
};

type Props = {
  benefit: IBenefitGroup;
  expandable?: boolean;
  handleSelectTier?: (tier: TierKey) => void;
  isFree?: boolean;
  onPlanChange?: (tier: IBenefitGroup, plan: IPlan | undefined) => void;
  plan?: string;
  selectable?: boolean;
  selected?: boolean;
  trackname: string;
  registrationFlow?: boolean;
};

export const TierCard = ({
  benefit,
  expandable = false,
  handleSelectTier,
  isFree = false,
  onPlanChange,
  plan = 'monthly',
  selectable = false,
  selected = false,
  trackname,
  registrationFlow = false,
}: Props) => {
  const { data: tiers } = useGetTiersQuery();
  const essentialCreditBuildingBenefit = tiers
    ?.find((tier) => tier.key === TierKey.Essential)
    ?.benefits.find((benefit) => benefit.key === 'credit-monthly-price');
  const { getFeatures, getSavingPerYear, getTierDescription } = useTiersData();

  const selectedPlan = benefit.plans?.find(({ key }) => key === plan);

  const features = getFeatures(
    benefit.benefits,
    benefit.key,
    essentialCreditBuildingBenefit,
  );
  const savingsPerYear = getSavingPerYear(
    benefit.average_savings_per_year,
    benefit.name,
  );
  const tierDescription = selectable ? getTierDescription(benefit.key) : '';

  return (
    <Card
      dataCy={`tier-card-${benefit.key}`}
      selectable={selectable}
      selected={selected}
      onSelect={() => {
        handleSelectTier && handleSelectTier(benefit.key);
        track({
          event: 'Clicked',
          properties: {
            name: trackname,
            tier_name: benefit.key,
            frequency: plan,
          },
        });
      }}
    >
      <Box>
        <TierCardHeader
          tier={benefit}
          plan={selectedPlan}
          selectable={selectable}
          selected={selected}
          description={tierDescription}
          isFree={isFree}
          registrationFlow={registrationFlow}
        />
        {expandable ? (
          <Collapse in={selected}>
            <Features
              disableMultiColourChecks={selectable}
              tierKey={benefit.key}
              features={features}
            />
          </Collapse>
        ) : (
          <Features
            disableMultiColourChecks={selectable}
            tierKey={benefit.key}
            features={features}
          />
        )}
        {!selectable && <SavingsPerYear>{savingsPerYear}</SavingsPerYear>}
      </Box>
      {selectable ? (
        <SavingsPerYear>{savingsPerYear}</SavingsPerYear>
      ) : (
        <ChangePlan
          tier={benefit}
          selectedPlan={selectedPlan}
          isFree={isFree}
          onPlanChange={onPlanChange}
          askForConfirmation={false}
        />
      )}
    </Card>
  );
};
