/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { KDSIcons } from 'assets/images/kds_icons';
import { ProgressBar } from 'components';
import {
  Paragraph,
  ParagraphSmall,
  TitleMedium,
  TitleSmall,
} from 'components/TypographyTw';

import fistbump from '../assets/images/referrals/fistbump-no-background.png';
import { usePagePermissions } from '../auth/hooks/usePagePermissions';
import { Template, ValueCopier } from '../components';
import { selectRefereeData, selectReferralCode } from './store/selectors';
import { referralActions } from './store/slice';

export const ReferralPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pagePermissions = usePagePermissions();
  const referralCode = useSelector(selectReferralCode);
  const referees = useSelector(selectRefereeData);

  useEffect(() => {
    if (!referralCode) {
      dispatch(referralActions.postReferralCodeRequest());
    }
    dispatch(referralActions.getReferralRefereesRequest());
  }, [dispatch, referralCode]);

  const referAndEarn = (
    <div className="bg-[linear-gradient(180deg,_#CCDFEE_66%,_#B8131A_34%)] rounded-lg w-full m-auto">
      <img
        className="w-full pb-4"
        src={fistbump}
        alt={`${intl.formatMessage({
          id: 'ReferralLandingPage.FistBumpImageAltTag',
          defaultMessage: 'Fist bump',
        })}`}
      />
      <div className="flex flex-col items-start justify-center px-6">
        <TitleMedium className="text-white mb-1">
          {intl.formatMessage({
            id: 'ReferralEarnPage.Title',
            defaultMessage: 'Refer & Earn',
          })}
        </TitleMedium>
        <Paragraph className="text-white">
          {intl.formatMessage({
            id: 'ReferralEarnPage.Subheading',
            defaultMessage: 'Get rewarded when you refer a friend',
          })}
        </Paragraph>
      </div>
    </div>
  );

  const referralLink = (
    <section>
      <Paragraph className="font-bold mb-0 mt-10">
        {intl.formatMessage({
          id: 'ReferralEarnPage.PersonalizedLink',
          defaultMessage: 'Share your personalized link',
        })}
      </Paragraph>
      <ValueCopier
        className="font-bold text-primary-300"
        trackName={'Referral Earn Page Code Copied'}
        value={
          referralCode ? `https://web.koho.ca/referral/${referralCode}` : ''
        }
      />
    </section>
  );

  const howItWorksList = [
    intl.formatMessage({
      id: 'ReferralEarnPage.HowItWorks.Invite',
      defaultMessage: 'Invite your friends',
    }),
    intl.formatMessage({
      id: 'ReferralEarnPage.HowItWorks.Signup',
      defaultMessage: 'They sign-up + make a purchase',
    }),
    intl.formatMessage({
      id: 'ReferralEarnPage.HowItWorks.Reward',
      defaultMessage: 'You both get an amazing reward!',
    }),
  ];

  const howItWorks = (
    <section>
      <Paragraph className="mb-0 mt-6 font-bold">
        {intl.formatMessage({
          id: 'ReferralEarnPage.HowItWorks',
          defaultMessage: 'How it works',
        })}
      </Paragraph>
      <List className="pt-0">
        {howItWorksList.map((item) => {
          return (
            <ListItem className="pl-0">
              <ListItemIcon className="min-w-[40px]">
                <KDSIcons.Icons.CheckmarkBold className="h-[24px] w-[24px]" />
              </ListItemIcon>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <ParagraphSmall>
        {intl.formatMessage({
          id: 'ReferralEarnPage.Deadline',
          defaultMessage:
            'The purchase must be made within 30 days of using the referral code.',
        })}
      </ParagraphSmall>
    </section>
  );

  const referralAmount =
    referees.filter(
      (ref) =>
        !ref.is_expired &&
        ref.progress_step === ref.progress_step_total &&
        ref.status_message.includes('20') &&
        ref.status_message.includes('$'),
    )?.length * 20;

  const referrersList = (
    <section className="pt-8 min-[960px]:pt-0 w-full min-[960px]:w-[300px]">
      <TitleSmall className="text-base md:mb-2 mt-0">
        {intl.formatMessage(
          {
            id: 'ReferralEarnPage.Referrers.Header',
            defaultMessage: `You've earned <color>$\{referralAmount}</color> from referring`,
          },
          {
            color: (message) => (
              <span className="text-secondary-200">{message}</span>
            ),
            referralAmount: referralAmount,
          },
        )}
      </TitleSmall>
      {referees.map((ref) => {
        return (
          <>
            <Paragraph className="m-0 pt-4 pb-1 font-bold">
              {ref.name}
            </Paragraph>
            <ProgressBar
              currentStep={ref.is_expired ? 0 : ref.progress_step}
              totalSteps={ref.progress_step_total}
              showSteps={false}
            />
            <Paragraph className="m-0 pb-4">{ref.status_message}</Paragraph>
            <div className="border-b border-solid border-grey-200" />
          </>
        );
      })}
    </section>
  );

  return (
    <Template name={'Referral Earn Page'}>
      {pagePermissions.canSeeReferralPage && (
        <Grid container justifyContent="space-around" alignItems="flex-start">
          <Grid
            sx={{
              px: [4, 4, 4, 0],
              py: 0,
              width: ['100%', 500, 500, 500],
            }}
          >
            {referAndEarn}
            {referralLink}
            {howItWorks}
          </Grid>
          {referees.length > 0 && (
            <Grid
              sx={{
                width: ['88%', '60%', '300px'],
              }}
            >
              {referrersList}
            </Grid>
          )}
        </Grid>
      )}
    </Template>
  );
};
