import { ForwardedRef, forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { selectTheme } from 'appState/appState.slice';
import { Icons } from 'assets/images/kds_icons/icons';

import { track } from '../analytics/analytics';
import { Paragraph } from './TypographyTw';

export const ValueCopier = ({
  label,
  value,
  className,
  trackName,
  ...props
}: {
  label?: string;
  value: string;
  className?: string;
  trackName?: string;
}) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyValue = () => {
    track({
      event: 'Copied Value Copier',
      properties: {
        label,
        trackName,
      },
    });
    if (window?.navigator && value) {
      window.navigator.clipboard.writeText(value);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2222);
  };

  return (
    <div {...props} className={className}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div onClick={handleTooltipOpen}>
          <Paragraph className="mb-1">{label}</Paragraph>
          <div
            onClick={handleCopyValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCopyValue();
              }
            }}
            role="button"
            tabIndex={0}
            className="flex rounded p-2 font-semibold relative overflow-auto border border-grey-200 border-solid items-center justify-between bg-grey-50"
          >
            {value}
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={intl.formatMessage({
                id: 'ValueCopier.Copied',
                defaultMessage: 'Copied!',
              })}
              placement="left"
              classes={{ touch: 'py-1 px-2 text-[0.625rem]' }}
            >
              {isLight ? (
                <CopyIconForCopier />
              ) : (
                <FileCopyOutlinedIcon
                  className="cursor-pointer absolute right-0 bg-grey-50"
                  color="primary"
                />
              )}
            </Tooltip>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export const CopyIconForCopier = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>((props, ref: ForwardedRef<HTMLDivElement>) => (
  <div {...props} ref={ref}>
    <Icons.Copy className="cursor-pointer bg-grey-50" />
  </div>
));
