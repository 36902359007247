import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleMedium } from 'components/TypographyTw';

export const M1ContestBanner = ({ onClick }) => {
  const intl = useIntl();

  return (
    <Box className="rounded-xl bg-secondary-75">
      <Box className="p-4">
        <TitleMedium>
          {intl.formatMessage({
            id: 'M1ContestTxBanner.Title',
            defaultMessage: 'The Growth Challenge',
          })}
        </TitleMedium>
        <Paragraph>
          {intl.formatMessage({
            id: 'M1ContestTxBanner.Title',
            defaultMessage:
              'Win up to $1000 or KOHO prizes! Just complete 2 tasks within 14 days—no luck needed.',
          })}
        </Paragraph>
      </Box>
      <Box className="p-4 bg-secondary-500 rounded-b-xl">
        <ButtonTw variant="secondary" onClick={onClick}>
          {intl.formatMessage({
            id: 'M1ContestTxBanner.CTA',
            defaultMessage: 'Get our app',
          })}
        </ButtonTw>
      </Box>
    </Box>
  );
};
