import { FieldErrors, UseFormSetValue } from 'react-hook-form';

import {
  InputValidationMsg,
  useValidationMsg,
} from 'components/forms/useValidationMsg';
import { KhInputVerificationCode } from 'components/inputs/KhInputVerificationCode';

export const RegistrationVerificationCodeComponent = ({
  errors,
  smsOtpInvalid,
  setSmsOtpInvalid,
  setValue,
}: {
  errors: FieldErrors<{
    otp: string;
  }>;
  smsOtpInvalid: boolean;
  setSmsOtpInvalid: (value: boolean) => void;
  setValue: UseFormSetValue<{ otp: string }>;
}) => {
  return (
    <>
      <KhInputVerificationCode
        errorState={!!errors?.otp || smsOtpInvalid}
        errorMessage={useValidationMsg(
          smsOtpInvalid ? InputValidationMsg.InvalidCode : errors.otp?.message,
        )}
        codeLength={6}
        onCodeChanged={() => {
          setSmsOtpInvalid(false);
        }}
        onCodeCompleted={(code) => {
          setValue('otp', code, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }}
        autoFocus={true}
        className="mb-4"
        data-cy="sms-otp-code-input"
        trackName="verification-code"
      />
    </>
  );
};
