import { createSelector } from '@reduxjs/toolkit';
import { rootApi } from 'apis/rootApi';
import { Money } from 'models';

type Plan = {
  id: string;
  amount: string;
  fee: string;
  plan_amount?: string;
};

export type OverdraftBundle = {
  id: string;
  status: 'active' | 'inactive' | 'pending_cancel';
  current_plan: Plan | null;
  eligible_plan: Plan;
  eligible_plans: Plan[] | null;
  accepted_plan: Plan | null;
  initial_plan: Plan;
};

const coverApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getCover: build.query<OverdraftBundle, void>({
      query: () => ({
        url: `1.0/overdraft`,
        method: 'GET',
      }),
      providesTags: ['Cover'],
    }),
    openCover: build.mutation<unknown, { plan_id: string }>({
      query: ({ plan_id }) => ({
        url: `1.0/overdraft`,
        method: 'POST',
        body: { plan_id },
      }),
      invalidatesTags: ['Cover'],
    }),
  }),
});

const selectGetOverdraftResult = coverApi.endpoints.getCover.select();

const selectGetOverdraftData = createSelector(
  selectGetOverdraftResult,
  (result) => result?.data,
);

export const selectEligiblePlans = createSelector(
  selectGetOverdraftData,
  (data) =>
    data?.eligible_plans ? data?.eligible_plans : [data?.eligible_plan],
);

export const selectMinumumEligiblePlan = createSelector(
  selectEligiblePlans,
  (eligiblePlans) =>
    [...eligiblePlans]?.sort((a, b) => {
      if (!a || !b) {
        return -1;
      }
      return new Money(a.amount).sub(b.amount).toNumber();
    })[0],
);

export const { useGetCoverQuery, useOpenCoverMutation } = coverApi;
