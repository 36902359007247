import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import phonesImageMC from 'assets/images/general/phoneSpongeCubeMC.png';
import { Card, FooterImageWrapper, LayoutBasic, Variants } from 'components';
import { TemplateTw } from 'layout/TemplateTw';
import { selectPasswordResetAuthToken } from 'passwordReset/store/selectors';

export const KycLayout = ({
  children,
  name,
  variant,
}: {
  children: ReactNode;
  name: string;
  variant?: Variants;
} & RouteComponentProps) => {
  const isResetPassword = useSelector(selectPasswordResetAuthToken);

  if (isResetPassword) {
    return (
      <LayoutBasic pageName="Password reset">
        <Card>{children}</Card>
        <FooterImageWrapper>
          <img
            src={phonesImageMC}
            alt=""
            className="w-[260px] relative z-[2]"
          />
        </FooterImageWrapper>
      </LayoutBasic>
    );
  } else {
    return (
      <TemplateTw name={name} variant={variant}>
        {children}
      </TemplateTw>
    );
  }
};
