import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  InputValidationMsg,
  useValidationMsg,
} from 'components/forms/useValidationMsg';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';

export const RegistrationSMSOTPInputComponent = ({
  register,
  setSmsOtpInvalid,
  errors,
  smsOtpInvalid,
}: {
  register: UseFormRegister<{
    otp: string;
  }>;
  setSmsOtpInvalid: (value: boolean) => void;
  errors: FieldErrors<{
    otp: string;
  }>;
  smsOtpInvalid: boolean;
}) => {
  const intl = useIntl();

  return (
    <KhInputTextTw
      {...register('otp', { onChange: () => setSmsOtpInvalid(false) })}
      placeholder={intl.formatMessage({
        id: 'RegistrationSmsOtpPage.InputPlaceholder',
        defaultMessage: 'Verification code',
      })}
      error={!!errors.otp}
      helperText={useValidationMsg(
        smsOtpInvalid ? InputValidationMsg.InvalidCode : errors.otp?.message,
      )}
      className="mb-4"
      data-cy="sms-otp-code-input"
      trackName="verification-code"
    />
  );
};
