import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetCardsQuery } from 'apis/cards';
import {
  personalizedOnboardingChecklistItem,
  useGetPersonalizedOnboardingQuery,
  useUpdatePersonalizedOnboardingItemMutation,
} from 'apis/personalizedOnboardingApi';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { AccountCard } from 'cards/models';
import { BoxTw } from 'components/BoxTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import {
  Paragraph,
  ParagraphSmall,
  TitleLarge,
  TitleMedium,
} from 'components/TypographyTw';
import DownloadApp from 'features/modals/DownloadApp';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateTw } from 'layout/TemplateTw';
import { selectProfileVerified } from 'profile/store/selectors';

import {
  isCanadaPostAffiliate,
  useGetAffiliatesQuery,
} from '../../apis/affiliates';
import kohoCanadaPostCard from '../../assets/images/cpc_card.png';
import kohoPurse from '../../assets/images/koho_purse.png';
import { RegistrationFeatureFlags } from '../../registration/models/RegistrationFeatureFlags';
import { CardStatusIndicator } from '../components/CardStatusIndicator';

export const MyCardsPage = (): React.ReactNode => {
  const intl = useIntl();
  const [updatePersonalizedOnboardingItem] =
    useUpdatePersonalizedOnboardingItemMutation();

  const { data: cards } = useGetCardsQuery();
  const userIsVerified = useSelector(selectProfileVerified);
  const { data: personalizedOnboarding } = useGetPersonalizedOnboardingQuery();
  const hasNotSeenCards =
    personalizedOnboarding?.onboarding_page.checklist.some(
      (item) =>
        item.key === personalizedOnboardingChecklistItem.HasViewedCards &&
        !item.completed,
    );

  const virtualCard = cards?.find((card) => card.is_virtual);
  const physicalCard = cards?.find((card) => !card.is_virtual);

  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (hasNotSeenCards) {
      updatePersonalizedOnboardingItem(
        personalizedOnboardingChecklistItem.HasViewedCards,
      );
    }
  }, [updatePersonalizedOnboardingItem, hasNotSeenCards]);

  return (
    <TemplateTw name="My Cards" variant="center">
      <TitleLarge className="mb-8">
        {intl.formatMessage({
          id: 'MyCards.Title',
          defaultMessage: 'My Cards',
        })}
      </TitleLarge>

      {physicalCard && <CardLink card={physicalCard} />}
      {virtualCard && <CardLink card={virtualCard} />}

      {!!cards?.length && !physicalCard && (
        <ButtonUnstyled
          trackName="MyCards OrderPhysicalCard"
          onClick={() => {
            let dest = '/cards/physical';

            if (userIsVerified === false) {
              dest = '/kyc/entry';
            }

            navigate(dest);
          }}
        >
          <PhysicalCardBanner />
        </ButtonUnstyled>
      )}

      <DownloadApp
        onClose={() => setDownloadModalOpen(false)}
        open={downloadModalOpen}
      />
    </TemplateTw>
  );
};

function CardLink({ card }: { card: AccountCard }) {
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';
  const intl = useIntl();

  const cardAlt = intl.formatMessage({
    id: 'MyCards.CardLink.CardAlt',
    defaultMessage: 'Your KOHO card',
  });

  const showCardNumber =
    card.card_status === 'active' ||
    card.card_status === 'locked' ||
    card.card_status === 'cancelled';

  const clickable =
    showCardNumber ||
    card.card_status === 'waiting for payment' ||
    card.card_status === 'shipped';

  const CardItem = ({ children }) => {
    if (clickable) {
      return (
        <ButtonUnstyled onClick={() => navigate('/cards/' + card.card_id)}>
          {children}
        </ButtonUnstyled>
      );
    }

    return <BoxTw>{children}</BoxTw>;
  };

  return (
    <CardItem>
      <BoxTw
        variant="shadow"
        className="p-4 mb-8 flex items-center light:border light:border-grey-75 light:justify-between"
        key={card.card_id}
      >
        <div className="flex items-center">
          <img
            alt={cardAlt}
            src={card.image_url}
            className="inline-block rounded mr-5 w-20"
          />
          <BoxTw className="inline-block">
            <TitleMedium className="py-0 mt-0 mb-1 light:text-2xl">
              {card.is_virtual
                ? intl.formatMessage({
                    id: 'MyCards.CardLink.VirtualCard',
                    defaultMessage: 'Virtual card',
                  })
                : intl.formatMessage({
                    id: 'MyCards.CardLink.PhysicalCard',
                    defaultMessage: 'Physical card',
                  })}
            </TitleMedium>
            <Paragraph className="py-0 my-0 text-grey-300">
              {showCardNumber && <>••••&nbsp;{card.last_four_digits}&nbsp;</>}

              {card?.is_locked && (
                <span className="text-primary-300">Locked&nbsp;</span>
              )}

              <CardStatusIndicator card={card} />
            </Paragraph>
          </BoxTw>
        </div>
        {clickable && (
          <BoxTw className="text-right">
            {isLight ? (
              <KDSIcons.Icons.ChevronLeft className="[&_path]:stroke-grey-200 [&_path]:stroke-2 w-4 h-4 rotate-180" />
            ) : (
              <KDSIcons.Icons.Drilldown />
            )}
          </BoxTw>
        )}
      </BoxTw>
    </CardItem>
  );
}

function PhysicalCardBanner() {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  const isAffiliateRegistrationEnabled =
    useFlags()[RegistrationFeatureFlags.EnableAffiliateRegistration];

  const { data: affiliateData, isLoading: isLoadingAffiliates } =
    useGetAffiliatesQuery(
      isAffiliateRegistrationEnabled ? undefined : skipToken,
    );
  const isCpAffiliate = isCanadaPostAffiliate(affiliateData?.affiliations);

  const lightCardImage = `${
    import.meta.env.VITE_STATIC_URL
  }/get-physical-card.png`;

  return (
    (!isLoadingAffiliates && (
      <BoxTw>
        {!isAffiliateRegistrationEnabled || !isCpAffiliate ? (
          <BoxTw className="p-4 flex items-center light:justify-between rounded-t-lg bg-primary-50 light:bg-secondary-75 light:pl-6 light:pr-8">
            <BoxTw className="inline-block mr-8">
              <TitleMedium className="py-0 mt-0 mb-1 light:text-3xl light:w-4/5">
                {intl.formatMessage({
                  id: 'MyCards.PhysicalCardBanner.Title',
                  defaultMessage: 'Get your physical KOHO card',
                })}
              </TitleMedium>
            </BoxTw>
            <img
              alt=""
              src={isLight ? lightCardImage : kohoPurse}
              className="inline-block rounded legacy:w-20 light:w-32"
            />
          </BoxTw>
        ) : (
          <BoxTw className="flex items-center justify-between light:bg-secondary-75 bg-primary-50">
            <BoxTw className="inline-block p-4 flex-[70]">
              <TitleLarge className="p-4">
                {intl.formatMessage({
                  id: 'MyCards.PhysicalCardBanner.Title',
                  defaultMessage: 'Get your physical KOHO card',
                })}
              </TitleLarge>
            </BoxTw>
            <BoxTw className="flex-[30] min-w-24 overflow-hidden">
              <img
                className="min-h-44 min-w-60"
                alt=""
                src={kohoCanadaPostCard}
              />
            </BoxTw>
          </BoxTw>
        )}
        <BoxTw className="p-4 mb-8 rounded-b-lg flex items-center justify-between bg-tiers-blue-300 light:bg-secondary-500">
          <ParagraphSmall className="py-0 my-0 text-white legacy:font-semibold">
            {intl.formatMessage({
              id: 'MyCards.PhysicalCardBanner.Description',
              defaultMessage:
                'Receive a free KOHO card in the mail in 14 days.',
            })}
          </ParagraphSmall>
          <BoxTw className="ml-4 whitespace-nowrap flex items-center ">
            <p className="font-bold text-white light:text-base">
              {intl.formatMessage({
                id: 'MyCards.PhysicalCardBanner.OrderCard',
                defaultMessage: 'Order card',
              })}
            </p>
            <KDSIcons.Icons.ArrowRight className="ml-2 text-white" />
          </BoxTw>
        </BoxTw>
      </BoxTw>
    )) || <></>
  );
}
