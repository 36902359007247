import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useLocation } from '@reach/router';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { Card, LayoutBasic, LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, SubtitleSmall, TitleMedium } from 'components/TypographyTw';
import { KycLayout } from 'kyc/components/KycLayout';
import { selectOnfidoIsLoading } from 'kyc/store/selectors';
import { onfidoActions } from 'kyc/store/slice';

export const KycBiometricsConsentPage = () => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const LockIcon =
    themeName === 'light' ? KDSIcons.Icons.SecurityLock : LockOutlinedIcon;
  const location = useLocation();
  const { locale, deepLinks } = useContext(LocaleContext);
  const dispatch = useDispatch();
  const isOnfidoLoading = useSelector(selectOnfidoIsLoading);
  const sourceStart = new URLSearchParams(location.search).get('src') ?? '';
  const isFromReg = new URLSearchParams(location.search).get('reg') ?? '';
  const [onfidoStarted, setOnfidoStarted] = useState(false);

  const startOnfido = () => {
    setOnfidoStarted(true);

    if (sourceStart) {
      dispatch(onfidoActions.setSourceStart(sourceStart));
    }

    dispatch(onfidoActions.initializeOnfido({ locale }));
  };

  const privacyLink = (str) => (
    <LinkTw
      className="underline"
      external={true}
      target="_blank"
      to={deepLinks.KOHO.PrivacyPolicyMC}
      trackName="Privacy Policy"
    >
      {str}
    </LinkTw>
  );

  const consentCard = (
    <Card>
      <TitleMedium>
        {intl.formatMessage({
          id: 'KycBiometricsConsentPage.Title',
          defaultMessage:
            'Consent to Use Facial Biometrics for Identity Verification',
        })}
      </TitleMedium>
      <Paragraph>
        {intl.formatMessage({
          id: 'KycBiometricsConsentPage.Content1',
          defaultMessage:
            'To verify your identity securely and fulfill regulatory obligations, KOHO works with Onfido, a third-party identity verification provider. This process involves capturing an image of your government-issued photo ID and a brief video selfie. Facial features are extracted from these images and compared to confirm your identity.',
        })}
      </Paragraph>
      <Paragraph>
        {intl.formatMessage(
          {
            id: 'KycBiometricsConsentPage.Content2',
            defaultMessage:
              'KOHO and Onfido only use your facial features to verify your identity and help prevent fraud. This data is stored for a limited period of time as necessary for fraud prevention purposes and then securely destroyed. It is not retained, shared or used for any other purpose. For details, please refer to KOHO’s <privacyLink>Privacy Policy</privacyLink>.',
          },
          {
            privacyLink: (link) => privacyLink(link),
          },
        )}
      </Paragraph>
      <Paragraph>
        {intl.formatMessage({
          id: 'KycBiometricsConsentPage.Content3',
          defaultMessage:
            'If you prefer not to use facial biometrics, alternative verification methods are available. For details, please contact us.',
        })}
      </Paragraph>
      <SubtitleSmall className="mb-5 mt-8 text-center text-grey-300">
        {intl.formatMessage({
          id: 'KycBiometricsConsentPage.Description',
          defaultMessage:
            'By selecting “Continue” you consent to the collection and use of your facial biometric data for identity verification.',
        })}
      </SubtitleSmall>
      <ButtonTw className="mb-6" onClick={startOnfido}>
        {intl.formatMessage({
          id: 'KycBiometricsConsentPage.CTA',
          defaultMessage: 'Continue',
        })}
      </ButtonTw>
      <div className="flex flex-col items-center">
        <LockIcon className="mb-3 w-5 h-5" />
        <SubtitleSmall className="text-center">
          {intl.formatMessage({
            id: 'KycBiometricsConsentPage.SecurityAssurance',
            defaultMessage:
              'Your information is secured with encryption and used for verification only.',
          })}
        </SubtitleSmall>
      </div>
    </Card>
  );

  const pageContent = (
    <div className="w-full">
      <div id="onfido-mount" className="flex justify-center w-full"></div>
      <div className="flex justify-center">
        {isOnfidoLoading ? <CircularProgress /> : !onfidoStarted && consentCard}
      </div>
    </div>
  );

  return isFromReg ? (
    <LayoutBasic pageName="KYC Biometrics Consent">{pageContent}</LayoutBasic>
  ) : (
    <KycLayout name="KYC Biometrics Consent">{pageContent}</KycLayout>
  );
};
