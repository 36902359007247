import { combineReducers, createAction } from '@reduxjs/toolkit';
import { rootApi } from 'apis/rootApi';
import { contactReducer } from 'contact/store/contact.slice';
import { InboundETransferReducer } from 'inboundETransfer/store/slice';
import tiersSlice from 'tiers/store/slice';

import accounts from '../accounts/store/slice';
import aliases from '../addFunds/store/slice';
import anonymousUser from '../anonymousUser/store/slice';
import { appStateReducer } from '../appState/appState.slice';
import auth from '../auth/store/slice';
import balance from '../balance/store/slice';
import payees from '../billPay/store/slice';
import brazeContentCards from '../braze/store/slice';
import cards from '../cards/store/slice';
import snackbarNotification from '../components/SnackbarNotification/store/slice';
import deviceVerification from '../deviceVerification/store/slice';
import hisaOnboarding from '../hisa/store/slice';
import onfido from '../kyc/store/slice';
import launchDarkly from '../libs/launchdarkly/store/slice';
import login from '../login/store/slice';
import passwordReset from '../passwordReset/store/slice';
import profile from '../profile/store/slice';
import referrals from '../referrals/store/slice';
import rewards from '../rewards/store/slice';
import transfer from '../sendMoney/store/slice';
import smsOtp from '../smsOtp/store/slice';
import transactions from '../transactions/store/slice';

export const clearStateAction = createAction('root/clearState');

export const appReducers = combineReducers({
  accounts,
  aliases,
  anonymousUser,
  appState: appStateReducer,
  auth,
  balance,
  cards,
  contact: contactReducer,
  deviceVerification,
  hisaOnboarding,
  inboundETransfer: InboundETransferReducer,
  launchDarkly,
  login,
  onfido,
  passwordReset,
  payees,
  profile,
  referrals,
  rewards,
  smsOtp,
  snackbarNotification,
  tiers: tiersSlice.reducer,
  transactions,
  transfer,
  brazeContentCards,
  [rootApi.reducerPath]: rootApi.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === clearStateAction.type.toString()) {
    // clear except auth
    const { auth } = state;
    state = { auth };
  }

  return appReducers(state, action);
};
