import { useIntl } from 'react-intl';

import { Typography } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';

export const CancelAnytime = () => {
  const intl = useIntl();

  return (
    <div className="text-center mb-6">
      <KDSIcons.Icons.Time className="mx-auto" />
      <p className="text-base legacy:font-black font-bold legacy:text-grey-400 text-grey-500">
        {intl.formatMessage({
          id: 'TierSelectionPage.CancelAnyTime',
          defaultMessage: 'Cancel any time',
        })}
      </p>
      <Typography className="font-normal text-sm max-w-[200px] mx-auto legacy:text-grey-400 text-grey-500">
        {intl.formatMessage({
          id: 'TierSelectionPage.CancelAnyTimeText',
          defaultMessage: 'Cancel your plan at any time - no penalties or fees',
        })}
      </Typography>
    </div>
  );
};
