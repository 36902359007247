import { InputValidationMsg } from 'components/forms/useValidationMsg';
import { z } from 'zod';

export const zodFormRegistrationSmsOtpVerify = z.object({
  otp: z
    .string()
    .min(6, { message: InputValidationMsg.InvalidCode })
    .max(6, { message: InputValidationMsg.InvalidCode }),
});

export type RegistrationSmsOtpVerifyForm = z.infer<
  typeof zodFormRegistrationSmsOtpVerify
>;
