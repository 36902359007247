import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Skeleton from '@material-ui/lab/Skeleton';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { selectAliasesState } from 'addFunds/store/selectors';
import {
  AutodepositAlias,
  useGetAutodepositAliasesQuery,
  useRegisterAliasMutation,
  useUnregisterAliasMutation,
} from 'apis/autodeposit';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import {
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components/TypographyTw';
import { TextInput, Validators } from 'components/forms';
import { SuccessPopupTw } from 'components/popups/SuccessPopupTw';
import { selectProfileEmail } from 'profile/store/selectors';

import { AddFundsShadowBox } from './AddFundsShadowBox';

export const AutodepositFormCard = () => {
  const intl = useIntl();

  const accountEmail = useSelector(selectProfileEmail);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);

  const [validEmail, setValidEmail] = useState(true);
  const [emailToRegister, setEmailToRegister] = useState(accountEmail);

  const { status: getQuery } = useGetAutodepositAliasesQuery();

  const aliases = useSelector(selectAliasesState);

  const handleRegisterSuccess = () => {
    setShowSuccessPopup(true);
    setTimeout(() => setShowSuccessPopup(false), 5000);
  };

  const handleUnregisterSuccess = () => {
    setShowRemovePopup(true);
    setTimeout(() => setShowRemovePopup(false), 5000);
  };

  const isRegistered: boolean =
    !!aliases?.length && getQuery === QueryStatus.fulfilled;

  let registeredEmail = '';

  if (isRegistered && aliases) {
    registeredEmail = aliases[0].email;
  }

  return (
    <AddFundsShadowBox>
      <BoxTw className="flex justify-between items-center">
        <TitleMedium className="mb-1">
          {intl.formatMessage({
            id: 'AutodepositFormCard.Title',
            defaultMessage: 'Autodeposit',
          })}
        </TitleMedium>

        <AliasStateIndicator query={getQuery} aliases={aliases || []} />
      </BoxTw>

      <Paragraph className="mt-1 mb-6">
        {intl.formatMessage({
          id: 'AutodepositFormCard.SaveTime',
          defaultMessage:
            'Autodeposit saves time by automatically depositing INTERAC e-Transfer® money into your account without security Q&A',
        })}
      </Paragraph>

      <TextInput
        label={intl.formatMessage({
          id: 'AutodepositFormCard.EmailFieldLabel',
          defaultMessage: 'Primary email',
        })}
        value={isRegistered ? registeredEmail : emailToRegister}
        onChange={(event) => setEmailToRegister(event.target.value)}
        onValidChange={(valid: boolean) => {
          // we short circuit the fact that `valid` is false the first time the email is
          // provided as `value`
          if (emailToRegister === accountEmail) {
            return false;
          }
          return setValidEmail(valid);
        }}
        validators={[Validators.email]}
        disabled={getQuery === QueryStatus.pending || isRegistered}
      />

      {getQuery === QueryStatus.fulfilled && !isRegistered && (
        <Paragraph className="my-6">
          {intl.formatMessage({
            id: 'AutodepositFormCard.Description',
            defaultMessage:
              'If your email is setup for auto-deposit with another bank you will need to enable autodeposit below.',
          })}
        </Paragraph>
      )}

      {getQuery === QueryStatus.fulfilled &&
        aliases[0]?.status === 'PENDING' && (
          <Paragraph className="my-6">
            {intl.formatMessage({
              id: 'AutodepostFormCard.Pending',
              defaultMessage:
                'INTERAC will send you an email to verify your autodeposit registration. The email will be valid for 24 hrs.',
            })}
          </Paragraph>
        )}

      {getQuery === QueryStatus.rejected && (
        <Paragraph className="mt-6 text-danger-300">
          {intl.formatMessage({
            id: 'AutodepostFormCard.GetError',
            defaultMessage:
              'Sorry, we are unable to check your autodeposit status. Please try again later.',
          })}
        </Paragraph>
      )}

      <BoxTw className="mt-4">
        <RegisterAliasButton
          onRegisterSuccess={() => handleRegisterSuccess()}
          onUnregisterSuccess={() => handleUnregisterSuccess()}
          disabled={!validEmail}
          query={getQuery}
          aliases={aliases}
          email={emailToRegister || ''}
        />
      </BoxTw>

      {showSuccessPopup && (
        <SuccessPopupTw
          message={intl.formatMessage({
            id: 'AutodepositFormCard.SuccessMessage',
            defaultMessage: 'Check your email to complete registration',
          })}
        />
      )}

      {showRemovePopup && (
        <SuccessPopupTw
          message={intl.formatMessage({
            id: 'AutodepositFormCard.RemoveMessage',
            defaultMessage: 'Email unregistered',
          })}
        />
      )}
    </AddFundsShadowBox>
  );
};

interface RegisterAliasButtonProps {
  disabled: boolean;
  query: QueryStatus;
  aliases: Array<AutodepositAlias>;
  email: string;
  onRegisterSuccess: () => void;
  onUnregisterSuccess: () => void;
}

const RegisterAliasButton = ({
  disabled,
  query,
  aliases,
  email,
  onRegisterSuccess,
  onUnregisterSuccess,
}: Readonly<RegisterAliasButtonProps>) => {
  const intl = useIntl();

  const [registerEmail, postStatus] = useRegisterAliasMutation();
  const [unregisterEmail, deleteStatus] = useUnregisterAliasMutation();

  const handleRegister = (email: string) => {
    registerEmail(email).then(() => {
      onRegisterSuccess();
    });
  };

  const handleUnregister = (id: string) => {
    unregisterEmail(id)
      .unwrap()
      .then(() => {
        onUnregisterSuccess();
      });
  };

  const disableButton =
    disabled ||
    query === QueryStatus.rejected ||
    postStatus.isError ||
    deleteStatus.isError;

  if (query === QueryStatus.pending) {
    return (
      <>
        <Skeleton variant="text" width={'100%'} height={60} />
        <Skeleton variant="rect" width={'100%'} height={40} className="mt-2" />
      </>
    );
  }

  if (aliases.length) {
    const alias: AutodepositAlias = aliases[0];

    return (
      <>
        {deleteStatus.isError && (
          <Paragraph className="my-6 text-danger-300">
            {intl.formatMessage({
              id: 'RegisterAliasButton.DeleteError',
              defaultMessage:
                'Sorry, we are unable to unregister your email. Please try again later.',
            })}
          </Paragraph>
        )}
        <ButtonTw
          disabled={disableButton}
          loading={deleteStatus.isLoading}
          trackName="Unregister autodeposit"
          onClick={() => handleUnregister(alias.alias_ref_id)}
          variant="clear"
          color="primary"
        >
          {intl.formatMessage({
            id: 'RegisterAliasButton.Unregister',
            defaultMessage: 'Unregister',
          })}
        </ButtonTw>
      </>
    );
  }

  return (
    <>
      {postStatus.isError && (
        <Paragraph className="my-6 text-danger-300">
          {intl.formatMessage({
            id: 'RegisterAliasButton.PostError',
            defaultMessage:
              'Sorry, we are unable to register your email. Please try again later.',
          })}
        </Paragraph>
      )}
      <ButtonTw
        disabled={disableButton}
        loading={postStatus.isLoading}
        trackName="Setup autodeposit"
        onClick={() => handleRegister(email)}
      >
        {intl.formatMessage({
          id: 'RegisterAliasButton.SetupButton',
          defaultMessage: 'Setup autodeposit',
        })}
      </ButtonTw>
    </>
  );
};

interface AliasStateIndicatorProps {
  query: QueryStatus;
  aliases: AutodepositAlias[];
}

const AliasStateIndicator = ({
  query,
  aliases,
}: Readonly<AliasStateIndicatorProps>) => {
  const intl = useIntl();

  if (query === QueryStatus.pending) {
    return <Skeleton variant="text" width={100} height={20} />;
  }

  if (query === QueryStatus.fulfilled && aliases.length) {
    const alias: AutodepositAlias = aliases[0];

    switch (alias.status) {
      case 'ACTIVE':
        return (
          <ParagraphSmall className="text-primary-300">
            {intl.formatMessage({
              id: 'AliasStateIndicator.IsRegistered',
              defaultMessage: '✓ Registered',
            })}
          </ParagraphSmall>
        );
      case 'NOT_ACTIVE':
        return (
          <ParagraphSmall className="text-danger-300">
            {intl.formatMessage({
              id: 'AliasStateIndicator.IsNotRegistered',
              defaultMessage: 'Unregistered',
            })}
          </ParagraphSmall>
        );
      case 'PENDING':
        return (
          <ParagraphSmall className="text-secondary-300">
            {intl.formatMessage({
              id: 'AliasStateIndicator.Pending',
              defaultMessage: 'Pending',
            })}
          </ParagraphSmall>
        );
    }
  }

  if (query === QueryStatus.fulfilled && aliases.length === 0) {
    return (
      <ParagraphSmall className="text-danger-300">
        {intl.formatMessage({
          id: 'AliasStateIndicator.IsNotRegistered',
          defaultMessage: 'Unregistered',
        })}
      </ParagraphSmall>
    );
  }

  // unknown input state
  return <></>;
};
