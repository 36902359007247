import { useState } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import { Backdrop, useMediaQuery } from '@material-ui/core';
import { ReactComponent as CheckMark } from 'assets/images/checkmark-success-filled.svg';
import confettiAnimation from 'assets/lottie/koho-confetti-fullscreen.json';
import clsx from 'clsx';
import { BoxTw } from 'components/BoxTw';
import { TitleMedium } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';

export const SuccessPopupTw = ({
  checkMarkStyle,
  message,
  useConfetti = false,
  useLightCheck = false,
}: {
  checkMarkStyle?: string;
  message: string;
  useConfetti?: boolean;
  useLightCheck?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = useMediaQuery('@media (max-width: 600px)');

  setTimeout(() => {
    setIsOpen(false);
  }, 2000);

  return (
    <Backdrop open={isOpen} className="z-10">
      <BoxTw
        className="bg-white rounded-xl py-8 px-11 flex flex-col items-center relative w-[300px] md:w-auto"
        data-cy="success-popup"
      >
        {useConfetti &&
          (isMobile ? (
            <Player
              autoplay
              loop
              src={confettiAnimation}
              className="absolute w-[400px] top-[-35px] left-[-45] z-[11]"
            />
          ) : (
            <Player
              autoplay
              loop
              src={confettiAnimation}
              className="absolute w-[inherit] top-[-40px] left-0 z-[11]"
            />
          ))}

        <CheckMark
          className={twMerge(
            clsx(
              useLightCheck &&
                '[&_circle]:fill-white [&_circle]:stroke-green-300 [&_path]:stroke-green-300 [&_circle]:stroke-[3] [&_path]:stroke-[3] overflow-visible',
            ),
            checkMarkStyle,
          )}
        />
        <TitleMedium className="mb-0 text-center light:text-base light:normal-case">
          {message}
        </TitleMedium>
      </BoxTw>
    </Backdrop>
  );
};
