import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';
import { useLazyGetCurrentTierQuery } from 'apis/tiers';
import linkedImage from 'assets/images/xtm/anyday-koho-linked.png';
import notLinkedImage from 'assets/images/xtm/anyday-koho-not-linked.png';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { Modal } from 'components/Modal';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { selectLoginShowWelcomeBackModal } from 'login/store/selectors';
import { loginActions } from 'login/store/slice';
import { selectProfileVerified } from 'profile/store/selectors';
import { selectIsPaidTier } from 'tiers/store/selectors';

const WelcomeModalXTM = ({ open = false }: { open: boolean }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [localOpen, setLocalOpen] = useState(open);
  const isPaidTier = useSelector(selectIsPaidTier);
  const [getTier] = useLazyGetCurrentTierQuery();

  const isWelcomeBackVariant = useSelector(selectLoginShowWelcomeBackModal);
  const cleared = useSelector(selectProfileVerified);

  const handleClose = () => {
    trackInput({
      type: 'Clicked',
      name: 'Modal - WelcomeXTM -- Cancel',
      details: { element: 'button' },
    });

    setLocalOpen(!localOpen);
    dispatch(loginActions.setShouldShowWelcomeBackModal(false));
    // refetch tier on close in case it has been updated in the BE;
    // this modal is shown to XTM users, whose tier will be asynchronously updated
    // after they log in
    getTier();
  };

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const doubleBr = () => (
    <>
      <br />
      <br />
    </>
  );
  const xtmRegistrationDescription = intl.formatMessage(
    {
      id: 'WelcomeModalXTM.RegistrationDescription',
      defaultMessage: `Save more and stress less with KOHO. <br></br> Need your payouts daily? Now you’ll get an auto-deposit straight into your account, and lots more perks! <br></br>Plus, get the 1st month of the Essential plan for free!`,
    },
    {
      br: doubleBr,
    },
  );

  const xtmWelcomeBackDescriptionNoTier = intl.formatMessage(
    {
      id: 'WelcomeModalXTM.WelcomeBackDescriptionNoTier',
      defaultMessage: `Need your payouts daily? Now you’ll get an auto-deposit straight into your account, and lots more perks!<br></br>Spend, save, exhale. <br></br>Plus, get the 1st month of the Essential plan for free!`,
    },
    {
      br: doubleBr,
    },
  );

  const xtmWelcomeBackDescriptionHasTier = intl.formatMessage(
    {
      id: 'WelcomeModalXTM.WelcomeBackDescriptionHasTier',
      defaultMessage: `Need your payouts daily? Now you’ll get an auto-deposit straight into your account, and lots more perks!<br></br>Spend, save, exhale.`,
    },
    {
      br: doubleBr,
    },
  );

  const xtmWelcomeBackDescriptionKYC = intl.formatMessage({
    id: 'WelcomeModalXTM.WelcomeBackDescriptionKYC',
    defaultMessage: `Verify your identity to get your payouts into your KOHO account!`,
  });

  const getModalDescription = () => {
    if (!isWelcomeBackVariant) {
      return xtmRegistrationDescription;
    }

    if (!cleared) {
      return xtmWelcomeBackDescriptionKYC;
    }

    if (isPaidTier) {
      return xtmWelcomeBackDescriptionHasTier;
    }

    return xtmWelcomeBackDescriptionNoTier;
  };

  const getModalTitle = () => {
    if (!isWelcomeBackVariant) {
      return intl.formatMessage({
        id: 'WelcomeModalXTM.Title',
        defaultMessage: 'Welcome',
      });
    }

    if (cleared) {
      return intl.formatMessage({
        id: 'WelcomeModalXTM.TitleWelcomeBack',
        defaultMessage: 'Welcome back',
      });
    }

    return intl.formatMessage({
      id: 'WelcomeModalXTM.TitleKYC',
      defaultMessage: 'Just one last step',
    });
  };

  const ctaAddMoney = intl.formatMessage({
    id: 'WelcomeModalXTM.CTA.AddMoney.Text',
    defaultMessage: 'Add money',
  });

  const ctaCancel = intl.formatMessage({
    id: 'WelcomeModalXTM.CTA.Cancel.Text',
    defaultMessage: 'Maybe later',
  });

  const ctaVerifyIdentity = intl.formatMessage({
    id: 'WelcomeModalXTM.CTA.VerifyIdentity.Text',
    defaultMessage: 'Verify my identity',
  });

  return (
    <Modal
      data-cy="welcome-modal-xtm"
      open={localOpen}
      onClose={handleClose}
      classes={{
        paper: 'bg-tiers-blue-300 light:bg-primary-500',
        root: '[&_.MuiSvgIcon-root]:text-white text-center light:[&_.MuiDialogContent-root]:pb-5',
      }}
      absolutePositioning={false}
      maxWidth="sm"
    >
      <BoxTw className="flex justify-center mb-10 sm:mb-16">
        <img
          src={cleared ? linkedImage : notLinkedImage}
          alt=""
          className="w-[167px] h-[68px["
        />
      </BoxTw>
      <TitleLarge className="legacy:text-white light:text-secondary-75">
        {getModalTitle()}
      </TitleLarge>
      <Paragraph
        className="text-white mb-8"
        data-cy="welcome-modal-xtm-description"
      >
        {getModalDescription()}
      </Paragraph>
      {cleared ? (
        <>
          <ButtonTw
            data-cy="welcome-modal-xtm-cta-add-money"
            className="mb-2"
            onClick={() => {
              navigate('/load');
              dispatch(loginActions.setShouldShowWelcomeBackModal(false));
            }}
            trackName="Modal - WelcomeXTM -- Add money"
            variant="secondaryInverted"
          >
            {ctaAddMoney}
          </ButtonTw>
          <ButtonTw
            data-cy="welcome-modal-xtm-cta-cancel"
            onClick={handleClose}
            className="text-white"
            variant="clearInverted"
          >
            {ctaCancel}
          </ButtonTw>
        </>
      ) : (
        <>
          <ButtonTw
            data-cy="welcome-modal-xtm-cta-kyc"
            variant="secondaryInverted"
            onClick={() => {
              navigate('/kyc/entry');
              dispatch(loginActions.setShouldShowWelcomeBackModal(false));
            }}
          >
            {ctaVerifyIdentity}
          </ButtonTw>
        </>
      )}
    </Modal>
  );
};

export default WelcomeModalXTM;
