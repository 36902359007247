export enum RegistrationFeatureFlags {
  InterestRate = 'web.registration-interest-rate',
  HomeAddressRequiredIfPOBoxUsed = 'web-registration-ban-po-box',
  EnableSMSDownloadLink = 'web-send-sms-download-link-on-reg',
  AlwaysRedirectToKYC = 'web-always-redirect-to-kyc-on-reg',
  EnableAffiliateRegistration = 'web.enable-affiliate-registration',
  EnableAffiliateRegistrationWithAffiliateUserIdentifier = 'web.enable-affiliate-registration-with-affiliate-user-identifier',
  KYCInterceptLoadingTimeout = 'web.registration.kyc-intercept-loading-timeout',
  KYCInterceptXTMLoadingTimeout = 'web.registration.kyc-intercept-xtm-loading-timeout',
  VerificationCodeInputEnabled = 'web.registration.enable-verification-code-input',
}
