import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as KOHOCardIcon } from 'assets/images/transactions/koho-card.svg';
import { LinkTw } from 'components/LinkTw';
import {
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  TitleSmall,
} from 'components/TypographyTw';

// TODO: Allow Stripe payment
enum PaymentMethods {
  KohoAccount = 'koho-account',
}

type Props = {
  balance: string;
  notEnoughFunds: boolean;
};

export const PaymentMethod = ({ balance, notEnoughFunds }: Props) => {
  const intl = useIntl();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    PaymentMethods.KohoAccount,
  );

  return (
    <Box>
      <TitleSmall className="text-grey-400 mb-2 mt-0">
        {intl.formatMessage({
          id: 'TiersUpgrade.Modal.PaymentMethod',
          defaultMessage: 'Payment method',
        })}
      </TitleSmall>
      <FormControl variant="outlined" className="w-full">
        <Select
          id="tiers-upgrade-payment-method"
          value={paymentMethod}
          onChange={(event) =>
            setPaymentMethod(event.target.value as PaymentMethods)
          }
        >
          <MenuItem value={PaymentMethods.KohoAccount}>
            <div className="flex flex-row items-center">
              <KOHOCardIcon className="w-[40px] mr-3" />
              <ParagraphBold className="text-grey-400 mr-1 m-0">
                KOHO
              </ParagraphBold>
              <Paragraph className="font-medium text-grey-400 m-0">
                ({balance})
              </Paragraph>
            </div>
          </MenuItem>
        </Select>
      </FormControl>
      {notEnoughFunds && (
        <>
          <ParagraphSmall className="legacy:text-[#E12D3F] text-danger-500 mt-2 font-bold leading-[18px]">
            {intl.formatMessage({
              id: 'TiersUpgrade.Modal.NotEnoughFunds',
              defaultMessage:
                'Your balance is too low. Please add money to upgrade.',
            })}
          </ParagraphSmall>
          <LinkTw
            to="/load"
            className="flex flex-row items-center legacy:font-black font-bold py-3 legacy:text-tiers-blue-400 no-underline hover:underline"
            trackName="tiers-confirm-modal-load-money"
            onClick={() =>
              track({
                event: 'Clicked',
                properties: { name: 'tiers-confirm-modal-load-money' },
              })
            }
            data-cy="tiers-upgrade-payment-add-money"
          >
            <KDSIcons.Icons.Add className=" scale(0.7) mr-2 mb-[2px] " />
            {intl.formatMessage({
              id: 'TiersUpgrade.Modal.AddMoney',
              defaultMessage: 'Add money',
            })}
          </LinkTw>
        </>
      )}
    </Box>
  );
};
