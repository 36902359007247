import React from 'react';
import { useIntl } from 'react-intl';

import { IconButton, IconButtonProps, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { handleCopyValue } from 'utility/handleCopyValue';

export const CopyButton = ({
  children,
  className,
  value,
  trackingLabel,
  ...props
}: IconButtonProps & {
  value: string;
  trackingLabel: string;
}) => {
  const intl = useIntl();
  const [toastOpen, setToastOpen] = React.useState(false);
  return (
    <>
      <IconButton
        className={twMerge(
          clsx(
            '[&_.MuiIconButton-label]:flex [&_.MuiIconButton-label]:items-center',
            className,
          ),
        )}
        onClick={() => {
          handleCopyValue(value, trackingLabel);
          setToastOpen(true);
        }}
        {...props}
      >
        {children}
        <KDSIcons.Icons.Copy
          className={twMerge(
            clsx('text-primary-300', !!children && 'ml-1 mb-1'),
          )}
        />
      </IconButton>
      <Snackbar
        autoHideDuration={2000}
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">
          {intl.formatMessage({
            id: 'CopyButton.Copied',
            defaultMessage: 'Copied to clipboard!',
          })}
        </Alert>
      </Snackbar>
    </>
  );
};
