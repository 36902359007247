/** @jsxImportSource theme-ui */
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useLocation } from '@reach/router';
import { isXTMAffiliate, useGetAffiliatesQuery } from 'apis/affiliates';
import { useLazyGetContestEligibilityQuery } from 'apis/contests';
import { BalanceModal, useBalanceModal } from 'balance/components/BalanceModal';
import { SuccessPopupTw } from 'components/popups/SuccessPopupTw';
import { M1ContestFeatureFlags } from 'contest/featureFlags';
import M1Contest from 'features/modals/M1Contest';
import WelcomeModal from 'features/modals/Welcome';
import WelcomeModalXTM from 'features/modals/WelcomeXTM';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateTw } from 'layout/TemplateTw';
import { selectLoginShowWelcomeBackModal } from 'login/store/selectors';
import {
  DowngradeSuccess,
  DowngradeSuccessProps,
} from 'tiers/components/downgradeConfirmationModal/DowngradeSuccess';

import { showBrazeInAppMessage } from 'libs/braze';

import { selectHasRetrievedAccounts } from './accounts/store/selectors';
import { InPageNotification } from './components';
import { desktop } from './theme';
import {
  TransactionsList,
  TransactionsSidebar,
  VirtualCardWithBalance,
} from './transactions/components';

export interface TransactionsLocationState {
  showWelcomeModal?: boolean;
  showUpgradePlanSuccess?: boolean;
  downgradePlanSuccess?: DowngradeSuccessProps;
}

export const TransactionsPage = () => {
  const intl = useIntl();
  const hasLoaded = useSelector(selectHasRetrievedAccounts);
  const isDesktop = useMediaQuery(desktop);
  const { state } = useLocation();

  const { isShowing: balanceIsShowing, showBalanceModal } = useBalanceModal();
  const showBalance = React.useCallback(
    () => showBalanceModal(true),
    [showBalanceModal],
  );

  const showWelcomeBackModal = useSelector(selectLoginShowWelcomeBackModal);

  const {
    showWelcomeModal: showFirstTimeExperience = false,
    showUpgradePlanSuccess = false,
    downgradePlanSuccess = undefined,
  } = (state as TransactionsLocationState) || {
    showWelcomeModal: false,
    showUpgradePlanSuccess: false,
    downgradePlanSuccess: undefined,
  };

  const { data: affiliateData, isLoading: isAffiliateDataLoading } =
    useGetAffiliatesQuery();
  const contestRunning = useFlags()[M1ContestFeatureFlags.PopupExperiment];
  const [getContestEligibility, contestResponse] =
    useLazyGetContestEligibilityQuery();

  const shouldShowWelcomeModal =
    showFirstTimeExperience &&
    !isAffiliateDataLoading &&
    !isXTMAffiliate(affiliateData?.affiliations);
  const shouldShowXTMWelcomeModal =
    (showFirstTimeExperience || showWelcomeBackModal) &&
    !isAffiliateDataLoading &&
    isXTMAffiliate(affiliateData?.affiliations);

  const showContestModal =
    showFirstTimeExperience &&
    contestRunning &&
    !isAffiliateDataLoading &&
    !shouldShowXTMWelcomeModal;

  const checkEligibility = useCallback(async () => {
    if (!showContestModal) return;

    await getContestEligibility().unwrap();
  }, [showContestModal, getContestEligibility]);

  React.useEffect(() => {
    checkEligibility();
  }, [showContestModal, checkEligibility]);

  showBrazeInAppMessage();

  return (
    <TemplateTw name="Transactions Page" className="light:bg-grey-50">
      <Grid
        className="legacy:p-6 legacy:pt-0 legacy:xl:p-4 legacy:xl:pt-2 light:xl:mt-2"
        container
        direction="row-reverse"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} lg={4} className="legacy:xl:pl-6">
          <div className="light:xl:mr-8 light:max-xl:mx-8">
            <TransactionsSidebar showBalance={showBalance} />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          className="legacy:xl:pr-6 xl:pr-8 xl:pl-0 light:px-8"
        >
          <div className="flex flex-col gap-6 light:p-6 light:pb-14 light:xl:pb-20 light:mb-14 light:xl:mb-0 light:bg-white light:rounded-xl">
            {isDesktop ? (
              <VirtualCardWithBalance showBalance={showBalance} />
            ) : null}
            {!hasLoaded && (
              <InPageNotification>
                <div className="legacy:text-primary-300">
                  {intl.formatMessage({
                    id: 'TransactionPage.LoadFunds',
                    defaultMessage:
                      'Load funds to your account to start spending! Add this card to your phone’s wallet to shop in-store.',
                  })}
                </div>
              </InPageNotification>
            )}
            <TransactionsList />
          </div>
        </Grid>
      </Grid>

      <BalanceModal
        open={balanceIsShowing}
        onClose={() => showBalanceModal(false)}
      />

      {showContestModal && contestResponse.data?.is_eligible && (
        <M1Contest open={showContestModal} />
      )}

      {(!showContestModal || // contest is disabled or not available
        contestResponse.isError ||
        // or contest is enabled but user is not in the test group
        (contestRunning && contestResponse.data?.is_eligible === false)) && (
        <>
          <WelcomeModal open={shouldShowWelcomeModal} />
          <WelcomeModalXTM open={shouldShowXTMWelcomeModal} />
        </>
      )}

      {showUpgradePlanSuccess && (
        <SuccessPopupTw
          useConfetti
          useLightCheck
          message={intl.formatMessage({
            id: 'TiersUpgrade.Modal.UpgradeSuccess',
            defaultMessage: 'Plan upgraded',
          })}
        />
      )}

      {downgradePlanSuccess && <DowngradeSuccess {...downgradePlanSuccess} />}
    </TemplateTw>
  );
};
