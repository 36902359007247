import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/react';
import {
  useGetDesignsQuery,
  useLazyGetCardsQuery,
  useRequestPhysicalMutation,
} from 'apis/cards';
import { KDSIcons } from 'assets/images/kds_icons';
import { CardSelector } from 'cards/components/cardSelector';
import { Modal } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import { Paragraph, ParagraphSmall, TitleLarge } from 'components/TypographyTw';
import DownloadApp from 'features/modals/DownloadApp';
import { TemplateTw } from 'layout/TemplateTw';
import { CardProductData } from 'models/CardProductData';
import { Profile } from 'profile/models/profile';
import { selectProfile, selectProfileAddress } from 'profile/store/selectors';

export const OrderPhysicalCardPage = () => {
  const intl = useIntl();

  const { data } = useGetDesignsQuery();
  const [getCards] = useLazyGetCardsQuery();

  const [requestCard, cardRequestStatus] = useRequestPhysicalMutation();

  const address = useSelector(selectProfileAddress);
  const profile = useSelector(selectProfile);

  const [isError, setIsError] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CardProductData | null>(
    null,
  );

  const [appModalOpen, setAppModalOpen] = useState(false);
  const [confirmStep, setConfirmStep] = useState(false);

  useEffect(() => {
    if (data) {
      setSelectedCard(data[0]);
    }
  }, [data]);

  const handleConfirmCard = async (card: CardProductData, profile: Profile) => {
    let design = card.card_design;

    try {
      await requestCard({ design, profile }).unwrap();
      // requestCard doesn't return the new account's card_id so we can't nav directly to its page
      await getCards().unwrap();
      navigate('/cards');
    } catch (e: unknown) {
      if ((e as FetchBaseQueryError)?.status !== undefined) {
        // handle http errors
        console.error(e);
      } else {
        Sentry.captureException(e as Error);
      }

      setIsError(true);
    }
  };

  return (
    <TemplateTw name="Order Physical" variant="center">
      {confirmStep || (
        <>
          <div className="text-center">
            <TitleLarge className="my-0">
              {intl.formatMessage({
                id: 'OrderPhysicalCard.Title',
                defaultMessage: 'Choose your card',
              })}
            </TitleLarge>
            <Paragraph>
              {intl.formatMessage({
                id: 'OrderPhysicalCard.Subtitle',
                defaultMessage:
                  'All of our cards have the same great benefits.',
              })}
            </Paragraph>
          </div>

          <BoxTw className="my-6">
            <CardSelector
              products={data}
              selected={selectedCard}
              onSelect={(card) => setSelectedCard(card)}
            />
          </BoxTw>

          <ButtonTw
            trackName="OrderPhysicalCard CardSelected"
            onClick={() => setConfirmStep(true)}
            disabled={selectedCard === null}
          >
            {intl.formatMessage({
              id: 'OrderPhysicalCard.NextStep',
              defaultMessage: 'Next',
            })}
          </ButtonTw>
        </>
      )}

      {confirmStep && selectedCard && (
        <>
          <div>
            <ButtonUnstyled
              onClick={() => setConfirmStep(false)}
              className="-ml-1"
            >
              <KDSIcons.Icons.ArrowRight className="rotate-180" />
            </ButtonUnstyled>
          </div>
          <div className="text-center">
            <TitleLarge className="mb-0">
              {intl.formatMessage({
                id: 'OrderPhysicalCard.Title',
                defaultMessage: 'Confirm your address',
              })}
            </TitleLarge>
            <Paragraph>
              {intl.formatMessage({
                id: 'OrderPhysicalCard.Subtitle',
                defaultMessage: "We'll mail your card to this address.",
              })}
            </Paragraph>
          </div>

          <BoxTw
            variant="shadow"
            className="p-4 mb-4 my-6 flex light:[box-shadow:rgba(0,0,0,0.1)_3px_3px_10px_0px]"
          >
            <BoxTw className="bg-grey-75 flex items-center mr-4 p-2 rounded-lg">
              <KDSIcons.Icons.DeliveryV2 className="text-primary-300" />
            </BoxTw>

            <BoxTw className="flex-1">
              <ParagraphSmall>{address?.line1}</ParagraphSmall>
              <ParagraphSmall>
                {address?.city}, {address?.province} {address?.postal_code}
              </ParagraphSmall>
            </BoxTw>

            <ButtonUnstyled
              className="font-bold text-primary-300"
              onClick={() => setAppModalOpen(true)}
            >
              {intl.formatMessage({
                id: 'OrderPhysicalCard.EditAddress',
                defaultMessage: 'Edit',
              })}
            </ButtonUnstyled>
          </BoxTw>

          {profile && (
            <ButtonTw
              trackName="OrderPhysicalCard Confirm"
              disabled={selectedCard === null || cardRequestStatus.isLoading}
              onClick={() => handleConfirmCard(selectedCard, profile)}
            >
              {intl.formatMessage({
                id: 'OrderPhysicalCard.Confirm',
                defaultMessage: 'Confirm',
              })}
            </ButtonTw>
          )}

          {profile && (
            <Modal
              open={isError}
              closeButton={false}
              onClose={() => setIsError(false)}
            >
              {/* TODO: replace asset
              <KDSIcons.Spot.Stop className="w-24 h-24 mt-6" />
              */}
              <TitleLarge className="mt-6">
                {intl.formatMessage({
                  id: 'OrderPhysicalCard.ErrorModal.ErrorTitle',
                  defaultMessage: "We couldn't process your order",
                })}
              </TitleLarge>
              <Paragraph className="mb-8">
                {intl.formatMessage({
                  id: 'OrderPhysicalCard.ErrorModal.ErrorDescription',
                  defaultMessage:
                    'Please try again, and if the problem continues, chat with our support team.',
                })}
              </Paragraph>
              <ButtonTw
                onClick={() => {
                  handleConfirmCard(selectedCard, profile);
                  setIsError(false);
                }}
              >
                {intl.formatMessage({
                  id: 'OrderPhysicalCard.ErrorModal.Done',
                  defaultMessage: 'Retry',
                })}
              </ButtonTw>
            </Modal>
          )}
        </>
      )}

      <DownloadApp onClose={() => setAppModalOpen(false)} open={appModalOpen} />
    </TemplateTw>
  );
};
