import { useIntl } from 'react-intl';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import headerImg from 'assets/images/kds_icons/spot/wait.svg';
import ButtonUnstyledTw from 'components/ButtonUnstyledTw';
import { Paragraph, TitleMedium } from 'components/TypographyTw';
import { Flex } from 'theme-ui';

export interface Props extends Omit<DialogProps, 'css'> {
  closeButton?: boolean;
  onClose: (event: object, reason: string) => void;
  open: boolean;
}

export const DeviceVerificationModal = ({
  onClose,
  open,
  children,
  closeButton = true,
  ...rest
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      <DialogTitle className="px-4 my-0 pb-0 max-w-[375px]">
        <Flex className="items-start">
          <img
            alt={`${intl.formatMessage({
              id: 'DeviceVerificationModal.ImageAltTag',
              defaultMessage: 'modal-header',
            })}`}
            src={headerImg}
          />

          {closeButton && (
            <div className="right-[5%] absolute">
              <ButtonUnstyledTw
                onClick={onClose}
                aria-label={intl.formatMessage({
                  id: 'DeviceVerificationModal.CloseButton.AriaLabel',
                  defaultMessage: 'Close modal',
                })}
              >
                <CloseIcon fontSize="large" />
              </ButtonUnstyledTw>
            </div>
          )}
        </Flex>

        <TitleMedium className="pl-4 mb-0">
          {intl.formatMessage({
            id: 'DeviceVerificationModal.Title',
            defaultMessage: 'Confirm your device to continue',
          })}
        </TitleMedium>
      </DialogTitle>
      <DialogContent className="px-8 mt-0 pt-3 max-w-[375px]">
        <Paragraph>
          {intl.formatMessage({
            id: 'DeviceVerificationModal.Content',
            defaultMessage:
              'Check your email inbox for a link to confirm this device. For security purposes, we require all users to do this to protect our community from fraudulent users.',
          })}
        </Paragraph>
        {children}
      </DialogContent>
    </Dialog>
  );
};
