import { SettingsFeatureFlags } from 'Settings/settings-feature-flags';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { AnalyticsFeatureFlags } from 'analytics/AnalyticsFeatureFlags';
import { BrazeFeatureFlags } from 'braze/models/BrazeFeatureFlags';
import { contactFeatureFlags } from 'contact/contact-feature-flags';
import { M1ContestFeatureFlags } from 'contest/featureFlags';
import { CoverFeatureFlags } from 'cover/cover-feature-flags';
import { CreditBuildingFeatureFlags } from 'creditBuilding/models/flags';
import {
  NewUsersAppDownloadExperiment,
  NewUsersAppDownloadExperimentValue,
} from 'downloadApp/models/flags';
import { HISAFeatureFlags } from 'hisa/hisaFeatureFlags';
import { IdentityVerificationFlags } from 'identity-verification/IdentityVerificationFlags';
import { LoginFeatureFlags } from 'login/models/LoginFeatureFlags';
import { MFAFeatureFlags } from 'mfa/models/MFAFeatureFlags';
import { referAndEarnFlags } from 'referAndEarn/referAndEarnFlags';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';
import { TxFeatureFlags } from 'transactions/transactions-feature-flags';
import { UserDelegationFeatureFlags } from 'userDelegation/models/userDelegationFeatureFlags';

export const launchDarklyFlags = {
  // key & default value for all features flag need to be defined here
  'web-nba-incentive-enabled': false,
  'web.argyle-delay-time': 5000,
  'web.enable-account-data-sources': false,
  'web.enable-employee-data-sources': false,
  'web.enable-my-cards-page': false,
  'web.enable-new-account-cards-endpoint': false,
  'web.enable-pay-cycle-data-sources': false,
  'web.enable-push-prompt': false,
  'web.enable-sitewide-alert': {},
  'web.enable-theme-switch': false,
  'web.flinks-improvements': '0',
  'web.kyc-with-onfido': false,
  'web.post-registration-funnel-variant': 'baseline',
  'web.sign-up.mastercard': false,
  [M1ContestFeatureFlags.PopupExperiment]: false,
  [M1ContestFeatureFlags.KillSwitch]: false,
  [CoverFeatureFlags.EnableCoverRegistrationFeature]: false,
  [AddFundsFeatureFlags.DepositViaBillPayEnabled]: false,
  [AddFundsFeatureFlags.DebitCardLoadingEnabled]: false,
  [AnalyticsFeatureFlags.RemoveSegmentProfileProperties]: false,
  [contactFeatureFlags.useOpenChatsEndpoint]: false,
  [HISAFeatureFlags.EnableOnboardingFlow]: false,
  [IdentityVerificationFlags.kycChallengesEnabled]: false,
  [RegistrationFeatureFlags.HomeAddressRequiredIfPOBoxUsed]: false,
  [RegistrationFeatureFlags.InterestRate]: '5',
  [referAndEarnFlags.enabled]: false,
  [RegistrationFeatureFlags.EnableSMSDownloadLink]: true,
  [RegistrationFeatureFlags.AlwaysRedirectToKYC]: false,
  [TiersFeatureFlags.EnableNonMemberTiersSelection]: false,
  [TiersFeatureFlags.EnableTiersStripePayment]: false,
  [TxFeatureFlags.ShowBalanceTooltip]: false,
  [MFAFeatureFlags.EnableSettings]: false,
  [MFAFeatureFlags.EnableNewLogin]: false,
  [MFAFeatureFlags.EnableDeviceVerification]: false,
  [RegistrationFeatureFlags.EnableAffiliateRegistration]: false,
  [CreditBuildingFeatureFlags.Enable]: false,
  [CreditBuildingFeatureFlags.LimitReached]: false,
  [CreditBuildingFeatureFlags.creditReportBundleEnabled]: false,
  [BrazeFeatureFlags.HomeCardsEnabled]: false,
  [NewUsersAppDownloadExperiment.Enable]:
    NewUsersAppDownloadExperimentValue.OldUser,
  [referAndEarnFlags.superReferralV3Enabled]: false,
  [RegistrationFeatureFlags.EnableAffiliateRegistrationWithAffiliateUserIdentifier]:
    false,
  [CoverFeatureFlags.stripeForOnboardingEnabled]: false,
  [CoverFeatureFlags.creditReportBundleEnabled]: false,
  [LoginFeatureFlags.XTMAccountLinkingEnabled]: false,
  [referAndEarnFlags.superReferralV4Enabled]: false,
  [RegistrationFeatureFlags.KYCInterceptLoadingTimeout]: 0,
  [RegistrationFeatureFlags.KYCInterceptXTMLoadingTimeout]: 0,
  [RegistrationFeatureFlags.VerificationCodeInputEnabled]: false,
  [UserDelegationFeatureFlags.Enable]: false,
  [SettingsFeatureFlags.EnabledLinkedAccountsPage]: false,
};
