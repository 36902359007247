import { useIntl } from 'react-intl';

import { LayoutBasic, TitleSmall } from 'components';

export const KycXTMLoadingPage = ({ path }: { path: string }) => {
  const intl = useIntl();

  return (
    <>
      <LayoutBasic pageName="KycXTMLoading" chat={false}>
        <div className="bg-[#EBD9FF] w-full flex-1 relative">
          <TitleSmall className="absolute z-10 uppercase text-center w-full top-12 text-lg">
            {intl.formatMessage({
              id: 'KycXTMLoadingPage.Title',
              defaultMessage:
                'One minute while we finish creating your account.',
            })}
          </TitleSmall>
          <video
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full object-cover"
            preload="auto"
            muted
            playsInline
            autoPlay
            loop
          >
            <source
              src={`${
                import.meta.env.VITE_STATIC_URL
              }/videos/xtm-koho-card-spinning.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      </LayoutBasic>
    </>
  );
};
