import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ListItemIcon, ListItemText } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { useLanguageMutation } from 'apis/users';
import { selectUserRefID } from 'profile/store/selectors';
import { twMerge } from 'tailwind-merge';

import { LOCALE_EN, LOCALE_FR } from '../components/I18NContextProvider';
import ButtonUnstyled from './ButtonUnstyledTw';
import { LocaleContext } from './I18NContextProvider';
import { Text } from './TypographyTw';

export const LanguageToggle = ({
  className,
  variant,
}: {
  className?: string;
  variant?: 'menuItem';
}) => {
  const toggleText = { en: 'FR', fr: 'EN' };
  const context = useContext(LocaleContext);
  const [languageMutation] = useLanguageMutation();
  const userRef = useSelector(selectUserRefID);

  const onClick = () => {
    const localeToSet = context.locale === LOCALE_EN ? LOCALE_FR : LOCALE_EN;
    context.setLanguage(localeToSet);
    localStorage.setItem('locale', localeToSet);

    if (userRef) {
      languageMutation({ userId: userRef, request: { language: localeToSet } });
    }
  };

  if (variant === 'menuItem') {
    return (
      <div onClick={onClick} className={twMerge('flex', className)}>
        <ListItemIcon>
          <LanguageIcon className="text-grey-400" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            style: {
              display: 'inline-flex',
              fontWeight: 800,
              color: '#212121',
            },
          }}
          className="self-end"
        >
          {toggleText[context.locale]}
        </ListItemText>
      </div>
    );
  }
  return (
    <ButtonUnstyled
      onClick={onClick}
      className={twMerge('flex flex-row', className)}
    >
      <LanguageIcon className="text-inherit" />
      <Text className="self-end text-inherit my-auto">
        {toggleText[context.locale]}
      </Text>
    </ButtonUnstyled>
  );
};
