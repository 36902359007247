import { rootApi } from './rootApi';

interface DirectDeposit {
  client_name: string;
  client_number: string;
  financial_institution_name: string;
  financial_institution_number: string;
  transit_number: string;
}

export const directDepositApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectDepositInfo: build.query<DirectDeposit, void>({
      query: () => ({
        url: '1.0/user/direct_deposit',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetDirectDepositInfoQuery } = directDepositApi;
