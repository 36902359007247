import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetCardImageQuery,
  useGetCardsQuery,
  useLockCardMutation,
} from 'apis/cards';
import { KDSIcons } from 'assets/images/kds_icons';
import { CardStatusIndicator } from 'cards/components/CardStatusIndicator';
import { LocaleContext } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import { LinkTw } from 'components/LinkTw';
import {
  Paragraph,
  ParagraphSmall,
  SubtitleMedium,
  TitleLarge,
  TitleSmall,
} from 'components/TypographyTw';
import DownloadApp from 'features/modals/DownloadApp';
import { TemplateTw } from 'layout/TemplateTw';

export const CardDetails = (props: { id: string }) => {
  const intl = useIntl();
  const context = useContext(LocaleContext);

  const { data: cards, isLoading } = useGetCardsQuery();

  const card = cards?.find((card) => card.card_id === props.id);

  useEffect(() => {
    // handle case when page is refreshed
    if (!card) navigate('/cards');
  }, [card]);

  const { data: imageUrl } = useGetCardImageQuery(card?.card_id ?? skipToken);

  const [toggleCard, toggleRequest] = useLockCardMutation();

  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);

  const showCardNumber =
    card?.card_status === 'active' || card?.card_status === 'locked';

  const inTransit =
    card?.card_status === 'waiting for payment' ||
    card?.card_status === 'shipped';

  return (
    <TemplateTw variant="center" name="Card Details" className="light:bg-white">
      <div>
        <ButtonUnstyled className="-ml-1" onClick={() => window.history.back()}>
          <KDSIcons.Icons.ArrowRight className="rotate-180" />
        </ButtonUnstyled>
      </div>

      <TitleLarge className="mb-8">
        {card?.is_virtual
          ? intl.formatMessage({
              id: 'MyCards.CardLink.VirtualCard',
              defaultMessage: 'Virtual card',
            })
          : intl.formatMessage({
              id: 'MyCards.CardLink.PhysicalCard',
              defaultMessage: 'Physical card',
            })}
      </TitleLarge>

      {card && (
        <>
          <BoxTw
            /**
             * We use a little trick here with relative/absolute positioning and fixed width/height.
             * This is to ensure that the skeleton and the image have the same dimensions while the
             * card image renders. We're pulling the card image from galileo, remember, so we can't
             * hide the skeleton as soon as we have the image URL. Doing so would cause this wrapping
             * Box to collapse because its size isn't known until the full image downloads. So what
             * we're doing here is fixing the size of the skeleton so the Box doesn't collapse, and
             * rendering the image as a relative-absolute element, covering the skeleton rather than
             * replacing it.
             */
            className="p-6 rounded-2xl text-center flex items-center flex-col relative mb-2 bg-tiers-blue-300 light:bg-primary-50"
          >
            <Skeleton
              variant="rect"
              className="block rounded-2xl w-[70vw] max-w-[386px] h-[44vw] max-h-[244px]"
            />
            {imageUrl && (
              <BoxTw className="absolute w-[70vw] max-w-[386px] max-h-[244px] h-[44vw]">
                <img
                  alt="Your KOHO card"
                  src={showCardNumber ? imageUrl : card.image_url}
                  className="rounded-2xl w-full"
                />
                {inTransit && (
                  <Paragraph className="rounded absolute left-0 top-0 ml-6 px-2 py-1 text-white inline-flex items-center text-sm bg-black/50">
                    <KDSIcons.Icons.CreditCardBold className="mr-2" />
                    <CardStatusIndicator color="inherit" card={card} />
                  </Paragraph>
                )}
              </BoxTw>
            )}
          </BoxTw>

          {inTransit && (
            <>
              <TitleLarge className="mb-0">
                {intl.formatMessage({
                  id: 'MyCards.CardLink.Ordered',
                  defaultMessage: 'Your card is being delivered',
                })}
              </TitleLarge>
              <Paragraph className="text-primary-300">
                <CardStatusIndicator card={card} />
              </Paragraph>
            </>
          )}

          {inTransit && (
            <BoxTw>
              <TitleSmall>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.NextSteps',
                  defaultMessage: 'Next steps',
                })}
              </TitleSmall>
              <StepRow icon={KDSIcons.Icons.Email}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.YourCardWillArrive',
                  defaultMessage: 'Your card will arrive in the mail',
                })}
              </StepRow>
              <StepRow icon={KDSIcons.Premium.Card}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.ActivateYourCard',
                  defaultMessage: 'Set your PIN and activate your card',
                })}
              </StepRow>
              <StepRow icon={KDSIcons.Icons.Bills}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.MakeAPurchase',
                  defaultMessage: 'Make a purchase and you’re done',
                })}
              </StepRow>
            </BoxTw>
          )}

          {!inTransit && (
            <BoxTw className="py-4 flex justify-between border-b border-grey-100">
              <div>
                <Paragraph className="my-1 font-bold">
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LockCard',
                    defaultMessage: 'Lock card',
                  })}
                </Paragraph>
                <ParagraphSmall>
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LockCardDescription',
                    defaultMessage: 'Instantly freeze your card from use.',
                  })}
                </ParagraphSmall>
              </div>
              <Switch
                color="primary"
                disabled={toggleRequest.isLoading || isLoading}
                checked={card.is_locked}
                onChange={() =>
                  toggleCard({ id: card.card_id, lock: !card.is_locked })
                }
              />
            </BoxTw>
          )}

          {!inTransit && (
            <BoxTw className="py-5 flex justify-between border-b border-grey-100">
              <Paragraph className="font-bold my-0">
                {card.is_virtual ? 'Need a replacement?' : 'Lost or stolen?'}
              </Paragraph>
              <ButtonUnstyled onClick={() => setDownloadModalOpen(true)}>
                <Paragraph className="text-primary-300 font-black my-0">
                  {card.is_virtual ? 'Request one' : 'Request a new card'}
                </Paragraph>
              </ButtonUnstyled>
            </BoxTw>
          )}

          {card.is_virtual && !inTransit && (
            <BoxTw className="py-5 flex justify-between border-b border-grey-100">
              <Paragraph className="font-bold my-0">
                {intl.formatMessage({
                  id: 'MyCards.CardLink.VirtualCard',
                  defaultMessage: 'What is a virtual card?',
                })}
              </Paragraph>
              <Paragraph className="text-primary-300 my-0">
                <LinkTw
                  external
                  trackName="what-is-a-virtual-card-link"
                  to={`https://help.koho.ca/${context.locale}/articles/2145453-what-is-a-virtual-card`}
                >
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LearnMore',
                    defaultMessage: 'Learn more',
                  })}
                </LinkTw>
              </Paragraph>
            </BoxTw>
          )}

          {!card.is_virtual && inTransit && (
            <ButtonTw
              onClick={() => setDownloadModalOpen(true)}
              className="mt-6"
            >
              {intl.formatMessage({
                id: 'MyCards.CardLink.ActivateCard',
                defaultMessage: 'Activate card',
              })}
            </ButtonTw>
          )}
        </>
      )}

      {!inTransit && (
        <div>
          <Accordion
            classes={{ root: 'p-0 shadow-none mt-3 before:content-none' }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon className="text-black" />}
              className="p-0 m-0"
              classes={{
                root: 'p-0 m-0 bg-grey-50 light:bg-white',
                content: 'p-0 m-0 bg-grey-50 light:bg-white',
                expanded: 'p-0 m-0 bg-grey-50 light:bg-white',
              }}
            >
              <div>
                <TitleSmall className="mb-1 font-bold">
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.AddToWallet',
                    defaultMessage: 'Add your card to a digital wallet',
                  })}
                </TitleSmall>
                <SubtitleMedium>
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.TapYourPhone',
                    defaultMessage: 'Tap your phone to make purchases.',
                  })}
                </SubtitleMedium>
              </div>
            </AccordionSummary>
            <AccordionDetails
              classes={{ root: 'p-0 block bg-grey-50 light:bg-white' }}
            >
              <AccordionRow icon={KDSIcons.Icons.Share}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.OpenYourDigitalWallet',
                  defaultMessage:
                    'Open your digital wallet (Apple, Google, etc.)',
                })}
              </AccordionRow>
              <AccordionRow icon={KDSIcons.Icons.AddLegacy}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.AddYourCard',
                  defaultMessage: 'Click to add a new card',
                })}
              </AccordionRow>
              <AccordionRow icon={KDSIcons.Icons.CreditCard}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.EnterYourVirtualCardDetails',
                  defaultMessage:
                    'Enter your virtual card details (number, expiry)',
                })}
              </AccordionRow>
              <AccordionRow icon={KDSIcons.Icons.Question}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.NeedMoreHelp',
                  defaultMessage: 'Need more help?',
                })}
                &nbsp;
                <LinkTw
                  external
                  trackName="what-is-a-virtual-card-link"
                  to={`https://help.koho.ca/${context.locale}/articles/2145453-what-is-a-virtual-card#h_dc628035ce`}
                >
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LearnMoreHere',
                    defaultMessage: 'Learn more here.',
                  })}
                </LinkTw>
              </AccordionRow>
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <DownloadApp
        onClose={() => setDownloadModalOpen(false)}
        open={downloadModalOpen}
        description={intl.formatMessage({
          id: 'CardDetails.DownloadApp.Description',
          defaultMessage: 'Download the app to request a card.',
        })}
      />
    </TemplateTw>
  );
};

function StepRow({ icon, children }) {
  const Icon = icon;

  return (
    <BoxTw className="flex items-center">
      <BoxTw className="flex items-center rounded-3xl p-2 bg-grey-75">
        <Icon className="text-primary-300" />
      </BoxTw>
      <Paragraph className="ml-4">{children}</Paragraph>
    </BoxTw>
  );
}

function AccordionRow({ icon, children }) {
  const Icon = icon;

  return (
    <BoxTw className="flex items-center">
      <Icon />
      <Paragraph className="ml-4 my-2 flex-1 leading-6">{children}</Paragraph>
    </BoxTw>
  );
}
