import { HttpResponse, http } from 'msw';

const accountsHandlers = [
  http.post(/\/accounts\/accounts/, () => {
    return HttpResponse.json({}, { status: 201 });
  }),

  http.get(/\/accounts\/accounts\/all/, () => {
    return HttpResponse.json({
      accounts: [
        {
          id: 'aaaaa-bbbbb-ccccc-ddddd',
          category: 'personal',
          group_id: 'eeeee-fffff-ggggg-hhhhh',
          relation: 'primary',
          owners: [
            {
              user_reference_id: 'MOCKED_USER_REFERENCE_ID',
              given_name: 'MOCKED_USER_GIVEN_NAME',
              family_name: 'Essential',
            },
          ],
          balance: '$0.00',
          legacy_number: '1111111111111',
          intended_use: '',
        },
      ],
    });
  }),
];

export default accountsHandlers;
